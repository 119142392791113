import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import toast from "react-hot-toast";

interface AgentAndRequester {
    id: string,
    type: string,
    attributes: {
        email: string
    }
}
interface Dropdown {
    id: string;
    email: string;
  }
  
  interface State {
    ccDropdowns: Dropdown[];
    requestersList: any[];
    isAddCcDisabled: boolean;
  }
export interface AllGroupsList {
    id: string,
    type: string,
    attributes: {
        name: string
    }
}
export interface AllAgentsList {
    id: string,
    type: string,
    attributes: {
        full_name: string
    }
}

export interface DepartmentList {
    id: string,
    type: string,
    attributes: {
        department_name: string
    }
}
interface TicketTemplate {
    title: string;
    index: string;
}


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    showModal : boolean;
    closeModal : (value: boolean)=> void;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    ccDropdowns: Dropdown[];
    requestersList: any[];
    isAddCcDisabled: boolean;
    isLoading: boolean,
    txtInputValue: string;
    templateList: TicketTemplate[];
    subject: string;
    selectedTemplate: string | number;
    subjectError: string,
    sourceError : string;
    statusError : string;
    selectedSource: string,
    selectedStatus: string,
    selectedPriority: string,
    allGroupList: AllGroupsList[],
    selectedGroup: string,
    filteredOptions: AllGroupsList[];
    allAgentsList: AllAgentsList[],
    filteredAgents: AllAgentsList[],
    allDepartmentList: DepartmentList[],
    filteredDepartmentList: DepartmentList[],
    description: string;
    descriptionError: string;
    categoryList: string;
    startDate: string;
    startTime: string;
    endDate: string;
    endTime: string;
    timeDifference: string;
    inputValue: string;
    selectedTags: string[];
    requesterError: string;
    isValidationError: boolean;
    startDateError : string;
    endDateError : string;
    changedDepartment: string;
    changedAgent: string;
    changedGroup: string;
    changedRequester: string;
    startTimeError:string;
    endTimeError: string;
    
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class IncidentTicketCustomFormController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    createTicketApiCallId: string = ''
    getSingalTemplateDataApiCallId: string = ''
    token: string = ''
    status: { title: string, id: string }[] = [
        {
            id: 'Open',
            title: 'Open',
        },
        {
            id: 'Closed',
            title: 'Closed',
        },
        {
            id: 'Pending',
            title: 'Pending',
        },
        {
            id: 'Resolved',
            title: 'Resolved'
        }
    ]
    source = [
        {
            id: '1',
            title: 'Email'
        },
        {
            id: '2',
            title: 'Service Desk'
        },
        {
            id: '3',
            title: 'Phone'
        },
    ]
    getRequesterDataAPICallId: string = "";
    getAllGroupsAPICallId: string = "";
    getAllAgentsListAPICallId: string = "";
    getAllDepartmentAPICallId: string = "";
    submitFormAPICallId: string = "";

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            ccDropdowns: [{ id: "", email: "" }], 
            requestersList: [], 
            isAddCcDisabled: false,
            isLoading: false,
            txtInputValue: '',
            subject: '',
            templateList: [],
            selectedTemplate: 'none',
            subjectError: '',
            sourceError : "",
            statusError:"",
            selectedSource: this.source[0].id,
            selectedStatus: this.status[0].id,
            selectedPriority: "Low",
            allGroupList: [],
            selectedGroup: '',
            filteredOptions: [],
            allAgentsList: [],
            filteredAgents: [],
            allDepartmentList: [],
            filteredDepartmentList: [],
            description: '',
            descriptionError: '',
            categoryList: '',
            startDate: "",
            startTime: "",
            endDate: "",
            endTime: "",
            timeDifference: "",
            inputValue: "",
            selectedTags: [],
            requesterError: '',
            isValidationError: false,
            startDateError : '',
            endDateError : '',
            changedDepartment: '',
            changedAgent: '',
            changedGroup: '',
            changedRequester: '',
            startTimeError:'',
            endTimeError: ''
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            )
            this.setState({
                isLoading: false
            })

            // This will rerdirect user to login page if token is not valid 
            if (responseJson && Array.isArray(responseJson.errors) && responseJson.errors.length > 0 &&
                (responseJson.errors[0].token === "Token has Expired" || responseJson.errors[0].token === 'Invalid token')) {
                this.goToLogin();
            }
            switch (apiRequestCallId) {
                case this.getSingalTemplateDataApiCallId:
                    this.handleTemplateDataResponse(responseJson);
                    break;

                case this.getRequesterDataAPICallId:
                    this.handleRequestersApiDataResponse(responseJson);
                    break;

                case this.getAllGroupsAPICallId:
                    this.handleGroupApiDataResponse(responseJson);
                    break;

                case this.getAllAgentsListAPICallId:
                    this.handleAgentsListResponse(responseJson);
                    break;

                case this.getAllDepartmentAPICallId:
                    this.handleDepartmentListResponse(responseJson);
                    break;

                case this.submitFormAPICallId:
                    this.handleCreateTicketResponse(responseJson);
                    break;
            }
        }
        // Customizable Area End
    }



    // Customizable Area Start
    async componentDidMount() {
        this.token = await getStorageData('authToken')
        this.getTemplateData()
        this.getAllRequesterList()
        this.getAllGroups()
        this.setState({ filteredOptions: this.state.allGroupList });
        this.getAllAgentsList()
        this.setState({ filteredAgents: this.state.allAgentsList });
        this.getAllDepartment()
        this.setState({ filteredDepartmentList: this.state.allDepartmentList })
    }

    setInputValue = (text: string) => this.setState({ txtInputValue: text });

    goToLogin() {
        const messages: Message = new Message(
            getName(MessageEnum.NavigationEmailLogInMessage)
        );
        messages.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(messages);
    }

    addCcDropdown = () => {
        this.setState((prevState) => {
            const { ccDropdowns, requestersList } = prevState;
            
            // Determine the maximum number of dropdowns that can be added
            const maxDropdowns = Math.min(requestersList.length, 5);
    
            // Check if the last dropdown has a selected value
            const lastDropdown = ccDropdowns[ccDropdowns.length - 1];
            if (!lastDropdown || !lastDropdown.id) {
                // If the last dropdown does not have a value, do not add a new one
                return null;
            }
    
            if (ccDropdowns.length < maxDropdowns) {
                const newDropdown: Dropdown = { id: "", email: "" };
                return {
                    ccDropdowns: [...ccDropdowns, newDropdown],
                    isAddCcDisabled: ccDropdowns.length + 1 >= maxDropdowns,
                };
            }
            
            return null;
        });
    };
          
    removeCc = (index: number) => {
        this.setState((prevState: Readonly<State>) => {
            const newCcDropdowns = prevState.ccDropdowns.filter((_, i) => i !== index);
            return {
              ccDropdowns: newCcDropdowns,
              isAddCcDisabled: false,
            };
        });
    }

    handleCcChange = (index: number, value: string) => {

        this.setState((prevState) => {
            const updatedDropdowns = [...prevState.ccDropdowns];
            updatedDropdowns[index].id = value;
    
            // Update isAddCcDisabled state based on the current state of dropdowns
            const allDropdownsFilled = updatedDropdowns.every(dropdown => dropdown.id !== "");
            
            return {
                ccDropdowns: updatedDropdowns,
                isAddCcDisabled: !allDropdownsFilled,
            };
        });
    };

    handleSourceChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const sourceValue = event.target.value as string;
        const isSourceEmpty = sourceValue.trim() === '';
        this.setState({
            selectedSource: sourceValue,
            sourceError: isSourceEmpty ? configJSON.sourceReq : ''
        });
    };


    handleSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const subjectValue = event.target.value;
        const isSubjectEmpty = subjectValue.trim() === '';
        this.setState({
            subject: subjectValue,
            subjectError: isSubjectEmpty ? configJSON.subjectReq : ''
        });
    }

    handleStatusChange = (event: React.ChangeEvent<{ value: unknown }>) => {

        const statusValue = event.target.value as string;
        const isStatusEmpty = statusValue.trim() === '';
        this.setState({
            selectedStatus: statusValue,
            statusError: isStatusEmpty ? configJSON.subjectReq : ''
        });
    }

    handlePriorityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ selectedPriority: event.target.value });
    };

    handleGroupSelectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event?.target?.value;
        const filteredOptions = this.state.allGroupList.filter(group =>
            group.attributes?.name?.toLowerCase().includes(inputValue?.toLowerCase())
        );
        this.setState({ filteredOptions });
    };

    handleAgentsSelectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event?.target?.value;
        const filteredAgents = this.state.allAgentsList.filter(agents =>
            agents.attributes?.full_name?.toLowerCase().includes(inputValue?.toLowerCase())
        );
        this.setState({ filteredAgents });
    }

    handleDepartmentSelectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event?.target?.value;
        const filteredDepartmentList = this.state.allDepartmentList.filter(department =>
            department.attributes?.department_name?.toLowerCase().includes(inputValue?.toLowerCase())
        );
        this.setState({ filteredDepartmentList });
    }

    handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const descriptionValue = event.target.value;
        const isDescriptionValid = descriptionValue.length >= 10;
        const isDescriptionEmpty = descriptionValue.trim() === '';
        let descriptionError = '';

        if (isDescriptionEmpty) {
            descriptionError = configJSON.descriptionRequired;
        } else if (!isDescriptionValid) {
            descriptionError = configJSON.descriptionCharcter;
        }

        this.setState({
            description: descriptionValue,
            descriptionError: descriptionError
        });
    }

    handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        const selectedDate = event.target.value;
        const currentDate = new Date().toISOString().split('T')[0];
        const { endDate } = this.state;

        if (selectedDate < currentDate) {
            this.setState({ startDate: selectedDate, startDateError: configJSON.startDateError });
        } else if (endDate && selectedDate > endDate) {
            this.setState({ startDate: selectedDate, startDateError: configJSON.startDateErrorValidation });
        } else {
            this.setState({ startDate: selectedDate, startDateError: '' },()=>{
                this.calculateTimeDifference();
            });
        }
    };

    handleStartTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedTime = event.target.value;
        if (!selectedTime) {
            this.setState({ startTime: selectedTime, startTimeError: configJSON.startTimeError });
        } else {
            this.setState({ startTime: selectedTime,startTimeError : "" }, ()=>{
                this.calculateTimeDifference();
            });
        }   
    };

    handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedDate = event.target.value;
        
        if (selectedDate < this.state.startDate) {
            this.setState({ endDate: selectedDate, endDateError: configJSON.endDateError });
        } else {
            this.setState({ endDate: selectedDate, endDateError: '' },()=>{
                this.calculateTimeDifference()
            });
        }

    };

    handleEndTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedEndTime = event.target.value;
        if (!selectedEndTime) {
            this.setState({ endTime: selectedEndTime, endTimeError: configJSON.endTimeError });
        } else {
            this.setState({ endTime: selectedEndTime, endTimeError: "" }, this.calculateTimeDifference);
        }
    };

    calculateTimeDifference = () => {
        const { startDate, startTime, endDate, endTime } = this.state;
        if(startDate && startTime && endDate && endTime){
            const startDateTime = new Date(startDate + "T" + startTime);
            const endDateTime = new Date(endDate + "T" + endTime);
            const difference = endDateTime.getTime() - startDateTime.getTime();
    
            const hoursDifference = Math.floor(difference / 1000 / 60 / 60) ?? 0;
            const minutesDifference = Math.floor((difference / 1000 / 60) % 60) ?? 0;
    
            this.setState({
                timeDifference: `${hoursDifference} hours ${minutesDifference} minutes`,
            });
        }
       
    };

    handleInputChange = (event: { target: { value: string } }) => {
        this.setState({ inputValue: event.target.value });
    };

    handleAddTag = (tags: string) => {
        if (tags && !this.state.selectedTags.includes(tags)) {
            this.setState({
                inputValue: '',
                selectedTags: [...this.state.selectedTags, tags]
            })
        }
    };
    handleDeleteTag = (tagToDelete: string) => () => {
        this.setState((tags) => {
            return {
                selectedTags: tags.selectedTags.filter((tags: string) => tags !== tagToDelete)
            }
        });
    };

    handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === configJSON.textEnter) {
            this.handleAddTag(this.state.inputValue);
        }
    };

    navigateUser = () => {
        const navigateToLoginMessage: Message = new Message(
            getName(MessageEnum.NavigationRequestersMessage)
        );
        navigateToLoginMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        navigateToLoginMessage.addData(getName(MessageEnum.UrlPathParamMessage), 'createRequester');
        this.send(navigateToLoginMessage);
    }

    getDepartmentValue = () => {
        let selectedDepartment = this.state.allDepartmentList.filter((data: DepartmentList) => {
            return parseInt(data.id) === parseInt(this.state.changedDepartment);
        });
        return selectedDepartment[0] || null;
    }

    getAgentsValue = () => {
        let selectedAgent = this.state.allAgentsList.filter((data: AllAgentsList) => {
            return parseInt(data.id) === parseInt(this.state.changedAgent);
        });
        return selectedAgent[0] || null;
    }

    getGroupValue = () => {
        let selectedGroup = this.state.allGroupList.filter((data: AllGroupsList) => {
            return parseInt(data.id) === parseInt(this.state.changedGroup);
        });
        return selectedGroup[0] || null;
    }

    handleChangeDepartment = (value: DepartmentList | null) => {
        this.setState({ changedDepartment: value ? value.id : '' });
    }

    handleChangeAgents = (value: AllAgentsList | null) => {
        this.setState({ changedAgent: value ? value.id : '' })
    }

    handleChangeGroup = (value: AllGroupsList | null) => {
        this.setState({ changedGroup: value ? value.id : '' })
    }

    handleRequesterChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const selectedRequestId = event.target.value as string;
        let requesterError = '';

        if (!selectedRequestId) {
            requesterError = configJSON.requesterRequire;
        }

        this.setState({ changedRequester: selectedRequestId, requesterError });
    };

    // API Integration
    getTemplateData = (index?: string) => {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: this.token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getSingalTemplateDataApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getSingalTemplateApiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getAllRequesterList = () => {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: this.token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getRequesterDataAPICallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAllRequestersApiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getAllGroups = () => {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: this.token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getAllGroupsAPICallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAllGroupsApiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getAllAgentsList = () => {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: this.token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getAllAgentsListAPICallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAllAgentsApiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getAllDepartment = () => {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: this.token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getAllDepartmentAPICallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAllDepartmentsApiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    onFormSubmiit = () => {
        const {endDate, startTime, endTime ,subject, description, ccDropdowns, startDate, selectedSource, selectedStatus} = this.state;
        let isError = false;

        // Validate requester
        if (ccDropdowns.length < 1 || !ccDropdowns[0].id) {
            this.setState({ requesterError: configJSON.requesterReq });
            isError = true;
        } else {
            this.setState({ requesterError: '' });
        }

        // Validate subject
        if (!subject.trim()) {
            this.setState({ subjectError: configJSON.subjectReqs });
            isError = true;
        } else {
            this.setState({ subjectError: '' });
        }

        // validate source
        if (!selectedSource.trim()) {
            this.setState({ sourceError: configJSON.subjectReq });
            isError = true;
        } else {
            this.setState({ sourceError: '' });
        }

        // validate status
        if (!selectedStatus.trim()) {
            this.setState({ statusError: configJSON.statusReq });
            isError = true;
        } else {
            this.setState({ statusError: '' });
        }

        // Validate description
        if (!description.trim()) {
            this.setState({ descriptionError: configJSON.descriptionRequired });
            isError = true;
        } else if (description.length < 10) {
            this.setState({ descriptionError: configJSON.descriptionCharcter });
            isError = true;
        } else {
            this.setState({ descriptionError: '' });
        }

        // Validate start date
        if (!startDate.trim()) {
            this.setState({ startDateError: configJSON.startDateError });
            isError = true;
        } else if(new Date(startDate) > new Date(endDate) ){
            this.setState({ startDateError: configJSON.startDateError });
            isError = true;
        }else {
            this.setState({ startDateError: '' });
        }

        // Validate start date
        if (!endDate.trim()) {
            this.setState({ endDateError: configJSON.endDateError });
            isError = true;
        } else {
            this.setState({ endDateError: '' });
        }

        // validate start time
        if (!startTime.trim()) {
            this.setState({ startTimeError: configJSON.startTimeError });
            isError = true;
        } else {
            this.setState({ startTimeError: "" });
        }

        // validate end time
        if (!endTime.trim()) {
            this.setState({ endTimeError: configJSON.endTimeError });
            isError = true;
        } else {
            this.setState({ endTimeError: "" });
        }

        if (isError) {
            this.setState({ isValidationError: true });
            return;
        }

        this.setState({ isValidationError: false });

        // If no validation errors, proceed with the form submission
        this.createTicket();
    };

    createTicket = () => {
        const header = {
            token: this.token
        };

        let formdata = new FormData();
        formdata.append("ticket[subject]", this.state.subject);
        formdata.append("ticket[description]", this.state.description);
        formdata.append("ticket[priority]", this.state.selectedPriority);
        formdata.append("ticket[planned_effort]", this.state.timeDifference);
        this.state.ccDropdowns.length > 1 && this.state.ccDropdowns.forEach((item, index) => {
            if (index !== 0) {
                formdata.append(`ticket[cc][]`, item?.id || "");
            }
        })
        formdata.append(`ticket[requester_id]`, this.state.ccDropdowns[0]?.id || "");
        formdata.append("ticket[status]", this.state.selectedStatus);
        formdata.append("ticket[source]", this.state.selectedSource);
        formdata.append(`ticket[group_id]`, this.state.changedGroup);
        formdata.append(`ticket[agent_id]`, this.state.changedAgent);
        formdata.append(`ticket[department_id]`, this.state.changedDepartment);
        formdata.append("ticket[category]", this.state.categoryList);
        formdata.append("ticket[tags]", this.state.inputValue);
        formdata.append(`ticket[ticket_template_id]`, '');

        const startDateString = this.state.startDate; 
        const startTimeString = this.state.startTime;
        const startDateTimeString = `${startDateString}T${startTimeString}`;
        const startDateTime = new Date(startDateTimeString);
        const startDateTimeISOString = startDateTime.toISOString();
        formdata.append("ticket[planned_startdate]", startDateTimeISOString);

        const endDateString = this.state.endDate; 
        const endTimeString = this.state.endTime;
        const endDateTimeString = `${endDateString}T${endTimeString}`;
        const endDateTime = new Date(endDateTimeString);
        const endDateTimeISOString = endDateTime.toISOString();
        formdata.append("ticket[planned_enddate]", endDateTimeISOString);

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

        this.submitFormAPICallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.createTicketApiEndPoint
        );
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }


    // API Responses
    handleTemplateDataResponse = (responseJson: { data: TicketTemplate[] }) => {
        if (responseJson.data) {
            this.setState({
                templateList: responseJson.data
            })
        }
    }

    handleRequestersApiDataResponse = (responseJson: { data: AgentAndRequester[] }) => {
        if (responseJson.data) {
            const requesterData = responseJson.data?.map((requester) => ({
                id: requester.id,
                type: 'someType',
                attributes: {
                    email: requester.attributes.email || ''
                }
            }));
            this.setState({
                requestersList: requesterData
            });
        }
    };

    handleGroupApiDataResponse = (responseJson: { data: AllGroupsList[] }) => {
        if (responseJson.data) {
            const groupData = responseJson.data.map((group) => ({
                id: group.id,
                type: 'someType',
                attributes: {
                    name: group.attributes.name || ''
                }
            }));
            this.setState({
                allGroupList: groupData
            })
        }
    }

    handleAgentsListResponse = (responseJson: { data: AllAgentsList[] }) => {
        if (responseJson.data) {
            const agentData = responseJson.data.map((agents) => ({
                id: agents.id,
                type: 'someType',
                attributes: {
                    full_name: agents.attributes.full_name || ''
                }
            }));
            this.setState({
                allAgentsList: agentData
            })
        }
    }

    handleDepartmentListResponse = (responseJson: { data: DepartmentList[] }) => {
        if (responseJson.data) {
            const departmentData = responseJson.data.map((department) => ({
                id: department.id,
                type: 'someType',
                attributes: {
                    department_name: department.attributes.department_name || ''
                }
            }));
            this.setState({
                allDepartmentList: departmentData
            })
        }
    }

    handleCreateTicketResponse = (responseJson: { data: TicketTemplate }) => {
        if (responseJson.data) {
            toast.success(configJSON.successTicketCreateMessage)
            this.props.closeModal(false)
            this.setState({
                isValidationError: false
            })
        }
    }

    // Customizable Area End
}
