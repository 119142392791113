import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { ChangeEvent } from "react";
import * as yup from 'yup';
import moment from "moment-timezone";
import toast from 'react-hot-toast';

interface Departments {
  id: string;
  type: string;
  attributes: {
    department_name: string;
    description: string;
    domains_for_department: string;
    department_head: string;
    prime_user: string;
  };
}

export interface NavigationItem {
  title: string;
  list?: NavigationItem[];
  href?: string;
}

interface RequesterAttributes {
  [key: string]: string | boolean | null;
}

interface AgentAttributes {
  [key: string]: string
}
export interface Requester {
  id: string;
  type: string;
  attributes: RequesterAttributes;
}

// Interface for agent fields

export interface DynamicField {
  id: string;
  type: string;
  attributes: DynamicFieldAttributes;
}
interface DynamicFieldAttributes {
  name: string;
  title: string | null;
  column_type: string;
  mandatory: boolean;
  form_type: string;
  status: boolean;
  optional: boolean;
}

interface SchemaFields {
  [key: string]: yup.StringSchema | yup.NumberSchema;
}

export interface AgentAndRequester {
  id: string;
  type: string;
  attributes: AgentAttributes;
}

const languages = ['English', 'French', 'Japanese', 'Spanish', 'Italian', 'Portuguese', 'German', 'Arabic', 'Russian', 'Korean'];
const time = ['Am', 'Pm'];
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}


interface S {
  // Customizable Area Start
  isLoading: boolean,
  txtInputValue: string;
  page: number;
  rowsPerPage: number;
  openListIndex: number;
  showModal: boolean;
  anchorEl: HTMLElement | null | undefined;
  open: boolean;
  isInfoModalOpen: boolean;
  editMode: boolean;
  requestersList: Requester[];
  requesterFields: DynamicField[];
  selected: string[]
  editRequester: Requester | null
  departmentList: Departments[];
  agentList: string[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RequestersController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  pathname = "requesters";
  getAllRequestersApiCallId: string = ''
  getAllRequesterFieldsApiCallId: string = ''
  removeRequesterApiCallId: string = ''
  requesterSaveApiCallId: string = ''
  requesteUpdateApiCallId: string = ''
  getDepartmentsListCallID: string = ''
  getAllAgentsApiCallId: string = ''
  token: string = ''
  navigationList: NavigationItem[] = [
    { title: 'Account settings', list: [
      {
        title: "Account",
        href: "accountSetting"
      },
      {
        title: "Plans & Billing",
        href: "plansBilling"
      },
      {
        title: "Service Desk Rebranding",
        href: "serviceDeskRebranding"
      },
      {
        title: "Email Notifications",
        href: "emailNotifications"
      },
    ] },
    {
      title: 'User management',
      list: [
        {
          title: 'Agents', href: '/agents'
        },
        {
          title: 'Roles', href: '/roles'
        },
        {
          title: 'Departments', href: '/departments'
        },
        {
          title: 'Department Fields', href: '/department-fields',
        },
        {
          title: 'Requesters', href: '/requesters'
        },
        {
          title: 'User Fields', href: '/userFields'
        },
        {
          title: 'CAB', href: '/cab'
        },
        {
          title: 'Agent groups', href: '/agentGroup'
        },
        {
          title: 'Requester groups', href: '/requester-groups',
        },
      ],
    },
    { title: 'Channels', list: [], },
    { title: 'Service management', list: [], },
    { title: 'Automation and productivity', list: [] },
    { title: 'Asset management', list: [] },
    { title: 'IT operations management', list: [], },
    { title: 'Project and workload management', list: [] },
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      page: 0,
      isLoading: false,
      txtInputValue: '',
      rowsPerPage: 5,
      openListIndex: -1,
      showModal: false,
      anchorEl: null,
      open: false,
      isInfoModalOpen: false,
      editMode: false,
      requestersList: [],
      requesterFields: [],
      editRequester: null,
      selected: [],
      departmentList: [],
      agentList: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const apiRequestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )
    this.setState({
      isLoading: false
    })

    switch (apiRequestId) {
      case this.getAllRequestersApiCallId:
        await this.handelgetAllRequestersApiResponse(responseJson)
        this.getRequestersFields()
        break;
      case this.getAllRequesterFieldsApiCallId:
        await this.handelgetAllFieldsApiResponse(responseJson)
        this.getDepartmentList()
        break;
      case this.removeRequesterApiCallId:
        this.handelRemoveRequesterResponse(responseJson)
        break;
      case this.requesterSaveApiCallId:
        this.handelCreateRequesterApiResponse(responseJson)
        break;
      case this.requesteUpdateApiCallId:
        this.handelUpdateAgentResponse(responseJson)
        break;
      case this.getDepartmentsListCallID:
        this.setState({
          departmentList: responseJson.departments.data
        });
        break;
      case this.getAllAgentsApiCallId:
        this.handelAllAgentResponse(responseJson)
        break;
    }

    // Customizable Area End
  }



  // Customizable Area Start
  async componentDidMount() {
    this.token = await getStorageData('authToken')
    this.getAllRequesters();
    this.getAllAgentsList()
  }

  getAllRequesters = () => {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.getRequestersApiContentType,
      token: this.token
    };
    let requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getRequestersApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRequestersApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getAllRequestersApiCallId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getRequestersFields = () => {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.getRequesterFieldsApiContentType,
      token: this.token
    };
    let requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    // Add method to request
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getRequesterFieldsApiMethod
    );

    // Add endpoint to request
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRequesterFieldsApiEndPoint
    );

    // Add header to request
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.getAllRequesterFieldsApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getAllAgentsList() {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.getAgentsListApiContentType,
      token: this.token
    };
    let requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAgentsListApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAgentsListApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getAllAgentsApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getDepartmentList() {
    const header = {
      "Content-Type": configJSON.departmentsApiContentType,
      token: this.token
    };
    let requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getDepartmentsListCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.departmentsApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.departmentsApiEndPoint
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  setInputValue = (text: string) => this.setState({ txtInputValue: text });
  
  openAccordian = (index: number) => this.setState((prevState) => ({ openListIndex: prevState.openListIndex === index ? -1 : index }));
  
  onPageChange = (_: unknown, page: number) => {this.setState({ page })};

  onRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>) => this.setState({ rowsPerPage: parseInt(event.target.value, 10) })

  isPathExist = (item: { href: string }[]) => {
    return item.some((item) => item.href === this.pathname);
  }

  handelInfoModal(value: boolean) {
    this.setState({
      isInfoModalOpen: value
    })
  }

  handleSelect = (id: string) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
  };

  handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = this.state.requestersList.map((data: Requester) => data.id);
      this.setState({ selected: newSelecteds });
      return;
    }
    this.setState({ selected: [] });
  };

  getTableData() {
    const { page, rowsPerPage, requestersList } = this.state;
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    return requestersList.slice(startIndex, Math.min(endIndex, requestersList.length));
  }

  handleInfoIconClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      isInfoModalOpen: true,
      anchorEl: event.currentTarget,
    });
  }

  handleModalOpen = (value: boolean) => { this.setState({ showModal: value }) }

  convertDataToFormValues = (agentData: Requester | null): RequesterAttributes => {
    if (agentData) {
      let initialValues: RequesterAttributes = {};
      this.state.requesterFields.forEach((field: DynamicField) => {
        initialValues[field.attributes.name] = agentData.attributes[field.attributes.name] || '';
      });
      return initialValues;
    }
    return {}
  }

  InitialValues = () => {
    const initialValues: RequesterAttributes = {}

    this.state.requesterFields.forEach((field: DynamicField) => {
      initialValues[field.attributes.name] = field.attributes.column_type === 'checkbox' ? false : '';
    });

    return initialValues;
  }


  createDynamicValidationSchema = () => {
    const schemaFields: SchemaFields = {};
    this.state.requesterFields.forEach(field => {
      if (field.attributes.status) {
        let validator;
        switch (field.attributes.column_type) {
          case 'email':
            validator = yup.string().trim().email('Invalid email address')
            break;
          case 'number':
            validator = yup.number().typeError('Must be a number');
            if(field.attributes.optional){
              validator = validator.positive('Must be a positive number')
              .integer('Must be an integer')
              .min(1000000000, 'Must be at least 10 digits')
              .max(999999999999999, 'Must not exceed 15 digits')
            }
            break;
           case 'textarea':
          case 'input':
          default:
            validator = yup.string().trim()
            if (field.attributes.optional) {
              validator = validator.min(5, 'Too Short!').max(150, 'Too Long!')
            }
            break;
        }
        if (field.attributes.optional) { validator = validator.required('This field is required')}
        schemaFields[field.attributes.name] = validator;
      }
    });
    return yup.object().shape(schemaFields);
  };

  getOptions = (data: DynamicField): string[] => {
    switch (data.attributes.name) {
      case 'department_id':
        return this.state.departmentList.map(dept => dept.attributes.department_name);
      case 'time_zone':
        return moment.tz.names();
      case 'reporting_manager':
        return this.state.agentList;
      case 'language':
        return languages;
      default:
        return time;
    }
  }

  handelSubmit = (values: RequesterAttributes, { setSubmitting }: { setSubmitting: (value: boolean) => void }) => {
    if (values) {
      if (this.state.editMode) {
        this.updateAgent(this.state.editRequester?.id, values);
      } else {
        this.setState({ isLoading: true });

        const header = {
          "Content-Type": configJSON.createRequesterApiContentType,
          token: this.token
        };

        const httpBody = {
          agent_and_requester: {
            "user_type": "requester",
            ...values
          }
        }

        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );

        this.requesterSaveApiCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.createRequesterApiEndPoint
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.createRequesterApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
      }
    } else {
      setSubmitting(false);
    }
  }

  updateAgent = (id: string | undefined, values: RequesterAttributes) => {
    if (id) {
      const header = {
        "Content-Type": configJSON.updateRequesterApiContentType,
        token: this.token
      };

      const httpBody = {
        agent_and_requester: {
          "user_type": "requester",
          ...values
        }
      }
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.requesteUpdateApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.updateRequesterApiEndPoint + id
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.updateRequesterApiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }


  handelEditRequesters = (requester: Requester) => {
    this.handleModalOpen(true)
    this.setState({
      editMode: true,
      editRequester: requester
    })
  }

  handleRemoveRequester = (id: string) => {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.removeRequesterApiContentType,
      token: this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.removeRequesterApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.removeRequesterApiEndPoint + id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.removeRequesterApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  renderTableHeading = () => this.state.editMode ? configJSON.updateRequesterLabel : configJSON.createRequesterLabel

  // it will handle get requesters response  
  handelgetAllRequestersApiResponse = async (responseJson: { data: Requester[] }) => {
    if (responseJson?.data) {
      this.setState({
        requestersList: responseJson.data
      })
    }
  }

  // it will handle get all requester fields response  
  handelgetAllFieldsApiResponse = async (responseJson: { data: DynamicField[] }) => {
    if (responseJson?.data) {
      this.setState({
        requesterFields: responseJson.data
      })
    }
  }

  // it will handle remove requester response  
  handelRemoveRequesterResponse = (responseJson: { message: string }) => {
    if (responseJson?.message === configJSON.requesterRemoveMessage) {
      toast.success(configJSON.requesterRemoveMessage2)
      this.getAllRequesters()
    }
  }

  // it will handle edit requester response  
  handelUpdateAgentResponse = async (responseJson: { data: Requester }) => {
    if (responseJson?.data?.id) {
      toast.success(configJSON.requesterUpdateMessage)
      const updatedRequesterList = this.state.requestersList.map((requester) =>
        requester.id === responseJson.data.id ? responseJson.data : requester
      );
      this.setState({
        showModal: false,
        requestersList: updatedRequesterList,
        editMode: false,
        editRequester: null
      });
    }
  }

  // it will handle edit requester response  
  handelCreateRequesterApiResponse = (responseJson: { data: Requester }) => {
    if (responseJson?.data?.id) {
      toast.success(configJSON.requesterAddMessage)
      this.setState({
        showModal: false,
        requestersList: [responseJson.data, ...this.state.requestersList]
      })
    }
  }

  // it will handle all agent response  
  handelAllAgentResponse = (responseJson: { data: AgentAndRequester[] }) => {
    if (responseJson?.data) {
      this.setState({
        agentList: responseJson.data.map((agent) => {
          const fullName = agent.attributes.full_name;
          return typeof fullName === 'string' ? fullName : '';
        })
      })
    }
  }
  // Customizable Area End
}
