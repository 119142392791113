import React from "react";

// Customizable Area Start
import {
  Button,
  Typography,
  Dialog,
  Box,
  Grid,
  TextField,
  MenuItem,
  Select,
  ThemeProvider,
  createTheme,
  OutlinedInput,
  FormControlLabel,
  RadioGroup,
  Radio,
  InputAdornment,
  Chip,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';

import {
  ExpandMoreRounded,
  AddCircle,
  RemoveCircleOutline,
} from "@material-ui/icons";
import IncidentTicketCustomFormController, { AllAgentsList, AllGroupsList, DepartmentList, Props, configJSON } from "./IncidentTicketCustomFormController";
import NavigationSideBar from "../../../components/src/NavigationSideBar";
import Loader from "../../../components/src/Loader";
import CloseIcon from '@material-ui/icons/Close';

const theme = createTheme({
  palette: {
    primary: {
      main: "#8760A9",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiRadio: {
      root: {
        color: '#8760A9',
      },
      colorSecondary: {
        '&$checked': {
          color: '#8760A9',
        },
      }
    },
    MuiFormLabel: {
      asterisk: {
        color: '#db3131',
        '&$error': {
          color: '#db3131'
        },
      }
    }
  }
});

// Customizable Area End

export default class IncidentTicketCustomForm extends IncidentTicketCustomFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <NavigationSideBar active={'Dashboards'} navigation={this.props.navigation} />
        <Loader loading={this.state.isLoading} />
        <Dialog
          open={this.props.showModal}
          onClose={() => this.props.closeModal(false)}
          fullWidth={true}
          maxWidth='lg'
          PaperProps={{ style: styles.dialogPaperStyle }}
          data-testId={"form_modal"}
        >
          <Box style={styles.boxStyle_1}>
            <Typography style={styles.fontStyle_1}>{configJSON.textCreateNewIncident}</Typography>
            <CloseIcon
              data-testId={"closeIcon"}
              style={{ cursor: 'pointer' }}
              onClick={() => this.props.closeModal(false)}
            />
          </Box>

          <Grid container spacing={4} style={styles.constainerSpacing}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography style={styles.fontStyle_2}>{configJSON.textSelectTemplate}</Typography>
            </Grid>

            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Typography style={styles.fontStyle_3}>{configJSON.textModalDesc}</Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Select
                input={<OutlinedInput />}
                IconComponent={ExpandMoreRounded}
                name={'template'}
                style={styles.dropdownStyle}
                fullWidth
                value={this.state.selectedTemplate}
                data-testId={"templete_data"}
              >
                {this.state.templateList.map((template) => (<MenuItem
                  onClick={() => this.getTemplateData(template.index)}
                  value={template.index} 
                  key={`key-${template.index}`}>
                    {template.title}
                </MenuItem>))}
              </Select>

              <Box style={styles.addCircle}>
                <Typography style={styles.textButtonStyle}> <AddCircle />{configJSON.textCreateNewTemplate}</Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={4} style={{ marginTop: '2%' }}>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Box style={styles.boxStyle_4}>
                <Typography style={styles.inputLabel}>{configJSON.textRequester}<span style={styles.textColorRed}> *</span></Typography>
                {this.state.ccDropdowns.map((recipient, index) => {
                  const selectedRequesters = this.state.ccDropdowns.map(cc => cc.id);
                  const availableRequesters = this.state.requestersList.filter(option => !selectedRequesters.includes(option.id) || option.id === recipient.id);

                  return (
                    <Box style={styles.selectBoxStyle} key={index}>
                      <Select
                        input={<OutlinedInput />}
                        IconComponent={ExpandMoreRounded}
                        name={'cc'}
                        value={recipient.id || ''}
                        style={styles.dropdownStyle}
                        data-testId={`addcc${index}`}
                        onChange={(event: React.ChangeEvent<{ name?: string; value: unknown }>) =>
                          this.handleCcChange(index, event.target.value as string)}
                        fullWidth
                      >
                        {availableRequesters.map((option) => (
                          <MenuItem
                            key={option.id}
                            value={option.id}
                            data-testId={"template_menu_item"}
                          >
                            {option.attributes.email}
                          </MenuItem>
                        ))}
                      </Select>
                      {index > 0 && (
                        <RemoveCircleOutline
                          data-test-id="remove_icon_click"
                          style={styles.removeIconStyle}
                          onClick={() => this.removeCc(index)}
                          data-testId={"removeCC"}
                        />
                      )}
                    </Box>
                  );
                })}
                <Box display={'flex'} gridGap={'12px'} justifyContent={'end'}>
                <Typography
                  style={{
                    ...styles.textButtonStyle,
                    ...(this.state.isAddCcDisabled || this.state.ccDropdowns.length >= this.state.requestersList.length ? styles.textButtonDisabled : {})
                  }}
                  data-testId={"addCC_btn"}
                  onClick={this.addCcDropdown}
                  component="button"
                  disabled={
                    this.state.isAddCcDisabled || 
                    this.state.ccDropdowns.length >= this.state.requestersList.length ||
                    this.state.ccDropdowns.some(dropdown => dropdown.id === "")
                  }
                >
                  <AddCircle /> {configJSON.textAddCC}
                </Typography>
                  <Typography style={styles.textButtonStyle} data-testId={"navigate"} onClick={() => this.navigateUser()}> 
                    <AddCircle /> {configJSON.textAddNewRequester}
                  </Typography>
                </Box>
              </Box>

              <Box style={styles.boxStyle_4}>
                <Typography style={styles.inputLabel}>{configJSON.textSubject}<span style={styles.textColorRed}> *</span></Typography>
                <TextField
                  variant="outlined"
                  value={this.state.subject}
                  onChange={this.handleSubjectChange}
                  data-testId={"changeSubject"}
                />
                {this.state.subjectError && <Typography style={styles.textColorRed}>{this.state.subjectError}</Typography>}
              </Box>

              <Box style={{ ...styles.boxStyle_4, marginTop: '15px' }}>
                <Typography style={styles.inputLabel}>{configJSON.textSource}<span style={styles.textColorRed}> *</span></Typography>
                <Select
                  input={<OutlinedInput />}
                  IconComponent={ExpandMoreRounded}
                  style={styles.dropdownStyle}
                  fullWidth
                  value={this.state.selectedSource}
                  onChange={this.handleSourceChange}
                  data-testId={"change_source"}
                >
                  {this.source.map((option) => (
                    <MenuItem
                      key={option.id}
                      value={option.id}
                    >
                      {option.title}
                    </MenuItem>
                  ))}
                </Select>
                {this.state.sourceError && <Typography style={styles.textColorRed}>{this.state.sourceError}</Typography>}

              </Box>

              <Box style={{ ...styles.boxStyle_4, marginTop: '15px' }}>
                <Typography style={styles.inputLabel}>{configJSON.textStatus}<span style={styles.textColorRed}> *</span></Typography>
                <Select
                  input={<OutlinedInput />}
                  IconComponent={ExpandMoreRounded}
                  style={styles.dropdownStyle}
                  fullWidth
                  value={this.state.selectedStatus}
                  onChange={this.handleStatusChange}
                  data-testId={"change_status"}
                >
                  {this.status.map((option) => (
                    <MenuItem
                      key={option.id}
                      value={option.id}
                    >
                      {option.title}
                    </MenuItem>
                  ))}
                </Select>
                {this.state.statusError && <Typography style={styles.textColorRed}>{this.state.statusError}</Typography>}

              </Box>

              <Box style={{ ...styles.boxStyle_4, marginTop: '15px' }}>
                <Typography style={styles.inputLabel}>{configJSON.textPriority}</Typography>
                <RadioGroup
                  aria-label="priority"
                  name="priority"
                  value={this.state.selectedPriority}
                  onChange={this.handlePriorityChange}
                  data-testId={"change_priority"}
                >
                  <FormControlLabel value="Low" control={<Radio />} label="Low" />
                  <FormControlLabel value="Medium" control={<Radio />} label="Medium" />
                  <FormControlLabel value="High" control={<Radio />} label="High" />
                  <FormControlLabel value="Urgent" control={<Radio />} label="Urgent" />
                </RadioGroup>
              </Box>

              <Box style={{ ...styles.boxStyle_4, marginTop: '15px' }}>
                <Typography style={styles.inputLabel}>{configJSON.textGroup}</Typography>
                <Autocomplete
                  options={this.state.filteredOptions}
                  getOptionLabel={(option) => option.attributes.name}
                  onChange={(event: React.ChangeEvent<{}>, value: AllGroupsList | null) => this.handleChangeGroup(value)}
                  data-testId={"select_group"}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      placeholder={configJSON.assignteam}
                      variant="outlined"
                      data-testId={"change_group"}
                      onChange={this.handleGroupSelectionChange}
                    />
                  )}
                />
              </Box>

              <Box style={{ ...styles.boxStyle_4, marginTop: '15px' }}>
                <Typography style={styles.inputLabel}>{configJSON.textAgent}</Typography>
                <Autocomplete
                  options={this.state.filteredAgents}
                  getOptionLabel={(option) => option.attributes.full_name}
                  value={this.getAgentsValue()}
                  onChange={(event: React.ChangeEvent<{}>, value: AllAgentsList | null) => this.handleChangeAgents(value)}
                  data-testId={"select_agents"}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      placeholder={configJSON.agentFieldPlaceholder}
                      variant="outlined"
                      onChange={this.handleAgentsSelectionChange}
                    />
                  )}
                />
              </Box>

              <Box style={{ ...styles.boxStyle_4, marginTop: '15px' }}>
                <Typography style={styles.inputLabel}>{configJSON.textDepartment}</Typography>
                <Autocomplete
                  options={this.state.filteredDepartmentList}
                  getOptionLabel={(option) => option.attributes.department_name}
                  data-testId={"select_department"}
                  value={this.getDepartmentValue()}
                  onChange={(event: React.ChangeEvent<{}>, value: DepartmentList | null) => this.handleChangeDepartment(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      placeholder={configJSON.agentFieldPlaceholder}
                      variant="outlined"
                      onChange={this.handleDepartmentSelectionChange}
                    />
                  )}
                />
              </Box>
            </Grid>

            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Box style={styles.boxStyle_4}>
                <Typography style={{ ...styles.inputLabel, marginTop: '10px' }}>{configJSON.textDescription}<span style={styles.textColorRed}> *</span></Typography>
                <TextField
                  variant="outlined"
                  minRows={12}
                  multiline
                  id="outlined-multiline-static"
                  fullWidth
                  placeholder={configJSON.enternote}
                  value={this.state.description}
                  onChange={this.handleDescriptionChange}
                  data-testId={"description_change"}
                />
                {this.state.descriptionError && <Typography style={styles.textColorRed}>{this.state.descriptionError}</Typography>}
              </Box>
              <Box style={{ ...styles.boxStyle_4, marginTop: '15px' }}>
                <Typography style={styles.inputLabel}>{configJSON.textCategory}</Typography>
                <Select
                  input={<OutlinedInput />}
                  IconComponent={ExpandMoreRounded}
                  name={'template'}
                  style={styles.dropdownStyle}
                  fullWidth
                  value={this.state.categoryList}
                >
                </Select>
              </Box>

              <Box style={{ marginTop: '15px' }}>
                <Typography style={styles.inputLabel}>{configJSON.textPlannedSdate} <span style={styles.textColorRed}> *</span></Typography>
                <Box style={styles.startDateEndBox}>
                  <Box style={{ ...styles.boxStyle_4, width: '45%' }}>
                    <TextField
                      id="date"
                      type="date"
                      variant="outlined"
                      onChange={this.handleStartDateChange}
                      value={this.state.startDate}
                      data-testId={"start_date"}
                      InputProps={{ inputProps: { min: this.state.startDate } }}
                    />
                    {this.state.startDateError && <Typography style={styles.textColorRed}>{this.state.startDateError}</Typography>}
                  </Box>

                  <Box style={{ ...styles.boxStyle_4, width: '45%' }}>
                    <TextField
                      id="time"
                      type="time"
                      variant="outlined"
                      onChange={this.handleStartTimeChange}
                      value={this.state.startTime}
                      data-testId={"start_time"}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300,
                      }}
                    />
                    {this.state.startTimeError && <Typography style={styles.textColorRed}>{this.state.startTimeError}</Typography>}
                  </Box>
                </Box>
              </Box>

              <Box style={{ marginTop: '15px' }}>
                <Typography style={styles.inputLabel}>{configJSON.textPlannedEdate} <span style={styles.textColorRed}> *</span> </Typography>
                <Box style={styles.startDateEndBox}>
                  <Box style={{ ...styles.boxStyle_4, width: '45%' }}>
                    <TextField
                      id="date"
                      type="date"
                      variant="outlined"
                      onChange={this.handleEndDateChange}
                      value={this.state.endDate}
                      data-testId={"end_date"}
                      InputProps={{ inputProps: { min: this.state.startDate } }}
                    />
                    {this.state.endDateError && <Typography style={styles.textColorRed}>{this.state.endDateError}</Typography>}
                  </Box>

                  <Box style={{ ...styles.boxStyle_4, width: '45%' }}>
                    <TextField
                      id="time"
                      type="time"
                      variant="outlined"
                      onChange={this.handleEndTimeChange}
                      value={this.state.endTime}
                      data-testId={"end_time"}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300,
                      }}
                    />
                    {this.state.endTimeError && <Typography style={styles.textColorRed}>{this.state.endTimeError}</Typography>}

                  </Box>
                </Box>
              </Box>

              <Box style={{ ...styles.boxStyle_4, marginTop: '15px' }}>
                <Typography style={styles.inputLabel}>{configJSON.textPlannedEffort}</Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  disabled
                  placeholder={configJSON.textPlannedEffortPlaceholder}
                  data-testId={"planned_effort"}
                  value={this.state.timeDifference}
                />
              </Box>

              <Box style={{ ...styles.boxStyle_4, marginTop: '15px' }}>
                <Typography style={styles.inputLabel}>{configJSON.textTags}</Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder={configJSON.textSearch}
                  value={this.state.inputValue}
                  onChange={this.handleInputChange}
                  onKeyDown={this.handleKeyDown}
                  data-testId={"change_tag"}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {this.state.selectedTags.map((tags, index) => (
                          <Chip
                            key={index}
                            label={tags}
                            onDelete={this.handleDeleteTag(tags)}
                            size="small"
                            data-testId={"add_tag"}
                          />
                        ))}
                      </InputAdornment>
                    )
                  }}
                />
              </Box>

              <Box style={styles.buttonBoxSyle}>
                <Typography
                  style={styles.cancelButton}
                  onClick={() => this.props.closeModal(false)}
                >
                  {configJSON.textCancel}
                </Typography>
                <Button
                  style={styles.submitButton}
                  variant="contained"
                  onClick={this.onFormSubmiit}
                  data-testId={"submit_form"}
                >
                  {configJSON.textSave}
                </Button>

              </Box>

            </Grid>
          </Grid>
        </Dialog>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles: Record<string, React.CSSProperties> = {
 
  textButtonDisabled: {
    color: '#A9A9A9', 
    cursor: 'not-allowed',
    opacity: 0.6, 
    border: 'none', 
    background: 'transparent', 
    padding: 0, 
  },
  textButtonEnabled: {
    cursor: 'pointer',
    border: 'none', 
    background: 'transparent', 
    padding: 0, 
  },
  constainerSpacing: {
    backgroundColor: '#F9F6FB',
    marginTop: '20px'
  },
  addCircle: {
    display: 'flex',
    justifyContent: 'flex-end'
  },

  startDateEndBox: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '10px'
  },

  selectBoxStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '10px'
  },
  container: {
    height: "100%",
    margin: "15px 15px 15px 300px",
    paddingBottom: "25px",
    fontFamily: "Manrope",
  } as const,
  input: {
    fontFamily: "Manrope",
    width: "100%",
    border: "1px solid #e6deed",
    height: "40px",
    padding: "10px 10px 10px 40px",
    fontSize: "16px",
    borderRadius: "10px",
  } as const,
  boxStyle_1: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },

  dialogPaperStyle: {
    padding: '25px 40px 25px 40px',
    borderRadius: '8px'
  },

  fontStyle_1: {
    fontFamily: 'Manrope',
    fontSize: '20px',
    fontWeight: 700,
    color: '#33354D'
  },

  fontStyle_2: {
    fontFamily: 'Manrope',
    fontSize: '18px',
    fontWeight: 700,
    color: '#33354D'
  },

  fontStyle_3: {
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontWeight: 400,
    color: '#33354D'
  },

  dropdownStyle: {
    color: '#A3A6C2'
  },

  buttonBoxSyle: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '20px',
    marginTop: '25px',
    border: 'none', // Ensure there's no border by default
    background: 'transparent', // Ensures no background is applied
    padding: 0, // Removes any default padding
  },

  cancelButton: {
    color: '#8760A9',
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontWeight: 700,
    cursor: 'pointer'
  },

  submitButton: {
    backgroundColor: '#8760A9',
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontWeight: 700,
    color: '#FFFFFF',
    textTransform: 'initial' as const,
    padding: '5px 35px 5px 35px',
  },

  textButtonStyle: {
    fontSize: '16px',
    color: '#8760A9',
    fontWeight: 700,
    marginTop: '12px',
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    cursor: "pointer"
  },

  inputLabel: {
    color: '#3D405C',
    fontSize: '12px',
    fontWeight: 500,
    marginBottom: '4px'
  },

  removeIconStyle: {
    cursor: 'pointer',
    fontSize: '24px',
    marginLeft: '5px'
  },

  boxStyle_4: {
    display: 'flex',
    flexDirection: 'column',
  },

  textColorRed: {
    color: 'red'
  }
};
// Customizable Area End
