import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isLoading: boolean,
    txtInputValue: string;
    showModal: boolean;
    isPopupOpen: boolean; 
  isSwitchOn: boolean; 

    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class DashboardsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    createTicketApiCallId: string = ''
    getSingalTemplateDataApiCallId: string = ''
    token: string = ''
    

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            isLoading: false,
            txtInputValue: '',
            showModal: false,
            isPopupOpen: true, 
            isSwitchOn: false
            
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            this.setState({
                isLoading: false
            })

            if (responseJson && Array.isArray(responseJson.errors) && responseJson.errors.length > 0 &&
                (responseJson.errors[0].token === "Token has Expired" || responseJson.errors[0].token === 'Invalid token')) {
                this.goToLogin();
            }
        }
        // Customizable Area End
    }



    // Customizable Area Start
    async componentDidMount() {
        this.token = await getStorageData('authToken')
    }




    // Web Events
    setInputValue = (text: string) => this.setState({ txtInputValue: text });

    goToLogin() {
        const messageData: Message = new Message(
            getName(MessageEnum.NavigationEmailLogInMessage)
        );
        messageData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(messageData);
    }

    handleModalState = (value: boolean) => this.setState({ showModal: value })

    // Customizable Area End
}
