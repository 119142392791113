import React from "react";

// Customizable Area Start


import { Formik, Form, Field, } from "formik";
import * as Yup from "yup";
import {
  Container,
  Box,
  Button,
  Typography,
  TextField,
  OutlinedInput,
  Paper,
  ThemeProvider,
  InputAdornment,
  IconButton,
  InputLabel,
  Link,
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { Path25 } from "./assets"
import { Path2 } from "./assets"
import { Path24 } from "./assets"
import { Path26 } from "./assets"
import { Path1 } from "./assets"
import { FinalLogo1 } from "./assets";
import { Toaster } from "react-hot-toast";
// Customizable Area End

// Customizable Area Start

const webStyle = {
  topFinallogo: {
    position: 'absolute',
    left: '120px',
    top: '94px',
    display: 'block',
    '@media (max-width: 1024px)': {
      left: '20px',   
      top: '20px',
      width: '80px',   
    },
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  pprBoxWrapper: {
    position: "relative",
    marginTop: "252px",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "251px",
    width: "500px", 
    zIndex:9999,
    '@media (max-width: 1024px)': {
      marginTop: '100px', 
    },
    '@media (max-width: 600px)': {
      width: '90%',
    },
  },
  pprLogin: {
    width: "100%",
    borderRadius: "8px",
    background: "#FFF",
    boxShadow:
      "0px 8px 32px 0px rgba(135, 96, 169, 0.16), 0px 4px 8px 0px rgba(135, 96, 169, 0.13)",
  },
  footerMainWrapperLogin: {
    display: "flex", justifyContent: "space-between"
  },
  path2StyleLogin: {
    position: "absolute", zIndex: 1, bottom: 0
  },
  path1StyleLogin: {
    position: "absolute", zIndex: 1, bottom: 0, left: 0
  },
  path24StyleLogin: {
    position: "absolute", zIndex: 1, bottom: 0, right: 0
  },
  path25StyleLogin: {
    position: "absolute", zIndex: 1, bottom: 0, right: "45px"
  }
};


const buttonContainerStyle = {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: 12,
  marginBottom: "20px",
  borderRadius: "0px 0px var(--1, 8px) var(--1, 8px)",
  background: "var(--basic-white, #FFF)",
};

const roleControl = { 
  width : "100%",
  marginBottom : "18px",
  height : "3.6rem",
  background : "none",
  borderColor : "rgba(0, 0, 0, 0.54)",
  borderRadius : "4px",
  padding : "8px"
}

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
      'Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character'
    ),
});


// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#8760A9",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
  // Customizable Area Start
  // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
    // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>

        <Toaster/>
        <Container
          style={{
            maxWidth: 1440, minHeight: "100vh", height: "1024px",
            overflow: "hidden", margin: "0 auto"
          }}
        >
          <Box
            sx={webStyle.topFinallogo}
          >
            <img src={FinalLogo1} alt="My Image" />
          </Box>
          <Box
            sx={webStyle.pprBoxWrapper}
          >
            <Paper
              style={webStyle.pprLogin}
            >
              <Box style={{ padding: "24px" }}>
                <Typography variant="h4" style={{ marginBottom: "18.5px" }} component="h2">
                  Login
                </Typography>
                <Typography style={{ marginBottom: "18.5px" }} variant="subtitle1" component="div">
                  Provide email address and password associated with your account.
                </Typography>


<InputLabel htmlFor="email" style={{ marginBottom: "5px" }}>
                          Role <span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <select style={roleControl} value={this.state.selectedRole} data-testid={"roleSelect"} onChange={(event) => this.roleSelect(event)}>
                          <option value="admin">Admin</option>
                          <option value="agent">Agent</option>
                        </select>
                <Formik
                  data-test-id="formikClickEvent"
                  initialValues={{
                    email: this.state.email || "",
                    password: this.state.password || "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    this.doWebEmailLoginSubmit(values.email, values.password)
                  }}
                >

                  {({ errors, touched }) => (
                    <Form translate={undefined}>
                    <Box>
                      <Box>
                  
                      </Box>
                      <Box>
                        <InputLabel htmlFor="email" style={{ marginBottom: "5px" }}>
                          Work email<span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <Field
                          data-test-id={"txtInputEmail"}
                          type="text"
                          name="email"
                          id="email"
                          placeHolder="Enter your work email address"
                          as={TextField}
                          fullWidth
                          variant="outlined"
                          error={touched.email && !!errors.email}
                          helperText={touched.email && errors.email}
                        />
                      </Box>
                      <Box sx={{ padding: "20px 0px", marginBottom: "10px" }}>
                        <InputLabel htmlFor="password" style={{ marginBottom: "5px" }}>
                          Enter password<span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <Field
                          data-test-id="txtInputPassword"
                          type={!this.state.enablePasswordField ? 'text' : 'password'}
                          name="password"
                          id="password"
                          as={OutlinedInput}
                          fullWidth
                          placeHolder="Enter Password"
                          error={touched.password && !!errors.password}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                edge="end"
                                onClick={this.btnPasswordShowHideProps.onPress}
                              >
                                {!this.state.enablePasswordField ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        <span id="testPassowrd" style={{ color: "red" }}>{touched.password && errors.password}</span>
                      </Box>
                      <Typography style={{ marginBottom: "50px", paddingRight: "5px" }}>
                        Forgot Password ?
                        <Link data-test-id="linkForgotPassword" onClick={this.redirectResetPassowrd} style={{
                          cursor: 'pointer'
                        }}> Reset Password</Link>
                      </Typography>
                      <Typography style={{ paddingRight: "5px", cursor : 'pointer' }}>
                        Don't have an account ?
                        <Link onClick={this.redirectSignup} > Sign Up</Link>
                      </Typography>
                      <Box sx={buttonContainerStyle}>
             
                        <Button
                          data-test-id={"btnEmailLogIn"}
                          type="submit"
                          style={{ backgroundColor: '#8760A9' }}
                        >
                          <span style={{
                            color: 'white',
                            textTransform: 'none',
                          }}>Log in</span>
                        </Button>
                      </Box>
                    </Box>
                   </Form>
                  )}
                </Formik>
              </Box>
            </Paper>
          </Box>
          <Box sx={webStyle.footerMainWrapperLogin}  >
            <Box style={{ display: "flex", }} >
              <Box sx={webStyle.path2StyleLogin}  >
                <img src={Path2} alt="Path2" />
              </Box>
              <Box sx={webStyle.path1StyleLogin} >
                <img src={Path1} alt="Path1" />
              </Box>
            </Box>
            <Box style={{ display: "flex", }}>
              <Box sx={webStyle.path24StyleLogin} >
                <img src={Path24} alt="Path24" />
              </Box >
              <Box sx={webStyle.path25StyleLogin} >
                <img src={Path25} alt="Path23" />
              </Box>
              <Box sx={webStyle.path24StyleLogin}>
                <img src={Path26} alt="Path26" />
              </Box>
            </Box>
          </Box>

        </Container>
      </ThemeProvider >
    // Customizable Area End
    );
  }
}