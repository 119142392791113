import React from "react";

// Customizable Area Start
import {
  Button,
  Input,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TablePagination,
  TableHead,
  Typography,
  Dialog,
  Box,
  Grid,
  TextField,
  InputLabel,
  styled,
} from "@material-ui/core";
import { Toaster } from 'react-hot-toast';
import {
  MoreVert,
  Search,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@material-ui/icons";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { infoIcon, infoIconOutlined, cancelIcon } from './assets';

import DepartmentsController, { DepartmentData, Props, configJSON, AgentsOptionList, FormInitalValue } from "./DepartmentsController";
import NavigationSideBar from "../../../components/src/NavigationSideBar";
import { ErrorMessage, Field, Form, Formik, FormikValues } from "formik";
import  { ControlProps } from "react-select";
import CommonModal from "./CommonModal.web";
import RightSidebar from "../../../components/src/RightSidebar";

const ErrorText = styled(Typography)({
  color: 'red',
})
// Customizable Area End

export default class Settings2 extends DepartmentsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderInfoIcon() {
    const icon = this.state.isInfoModalOpen ? infoIcon : infoIconOutlined;
    return (
      <img
        src={icon}
        aria-describedby="infoIcon"
        style={styles.infoIconsStyle}
        onClick={(e: React.MouseEvent<HTMLElement>) => this.handleInfoIconClick(e)}
        data-test-id='openInfoModal'
      />
    );
  }
  renderField = (field: any) => {
    const { name, column_type } = field.attributes;
console.log(column_type, 'colimptypee')
    switch (column_type) {
        case 'input':
        case 'email':
        case 'number':
            return (
                <Field
                    name={name}
                    type={column_type}
                    id={name}
                    as={TextField}
                    variant="outlined"
                    fullWidth
                />
            );
        case 'textarea':
            return (
                <Field
                    name={name}
                    as={TextField}
                    multiline
                    variant="outlined"
                    fullWidth
                    minRows={6}
                    id="outlined-multiline-static"
                />
            );
  
     
    }
};


  renderForm(initialValues: FormInitalValue, handleSubmitFunction: (value: FormikValues) => void, formTestId: string) {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={this.getValidationSchema()}
        onSubmit={this.handlesubmit}
        data-test-id={formTestId}
      >
                        
                            {({ errors, touched, isSubmitting }: { errors: any, touched: { [key: string]: string }, isSubmitting: boolean }) => (
          <Form translate={undefined} style={styles.form_main_box}>
            <Grid container spacing={2}>
    
               {this.state.departmentFields.map((field: any) => {
                                            if (!field.attributes.status) return null;
                                            const { name, title, optional } = field.attributes;
                                            return (
                                                <Grid item xs={6} key={`key-${field.id}-${name}`}>
                                                    <InputLabel style={{ marginBottom: "5px" }}>
                                                        {title} {optional === true && <span style={{ color: "red" }}>*</span>}
                                                    </InputLabel>
                                                    {this.renderField(field)}
                                                    {(touched[field.attributes.name] || isSubmitting) &&
                                                        <ErrorMessage name={field.attributes.name} render={(msg: string) => <ErrorText>{msg}</ErrorText>} />}
                                                </Grid>
                                            )
                                        })}
            </Grid>
            <Box mt={'30px'} style={styles.modal_button_main_box}>
              <Button data-test-id='modal_Cancel_Btn' type="reset" style={styles.cancel_button} onClick={this.handleEditModalClose}>{configJSON.cancelBTN}</Button>
              <Button type="submit" style={styles.add_button} >{configJSON.saveBTN}</Button>
            </Box>
          </Form>
        )}
      </Formik>
    )
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <NavigationSideBar active={'Settings'} navigation={this.props.navigation} />
        <Toaster />
        <Box style={styles.container}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span style={styles.navigation}>
              Settings {">"} User management {">"} Department Fields
            </span>
            <Box style={{ borderRadius: '5px', background: '#e6deed', }}>
              <span style={{ width: '36px', height: '36px', }}>
                <MoreVert style={{ width: '24px', height: '24px', color: 'black', padding: '5px' }} />
              </span>
            </Box>
          </Box>

          <Box
            style={{
              marginTop: "60px",
              marginBottom: "50px",
            }}>
            <Box style={styles.department_main_box}>
              <h1 style={styles.heading} dat-test-id="departmentTestId">{configJSON.departmentLabel}</h1>
              {this.renderInfoIcon()}
            </Box>
          </Box>

          <Box style={{ marginBottom: "32px", position: "relative", }}>
            <Search style={styles.search_icon} />
            <Input
              disableUnderline={true}
              data-test-id="searchInput"
              onChange={(event) => this.handleSearchInputChange(event)}
              style={styles.input}
              placeholder={"Search..."}
            />
          </Box>
          <Box style={{ display: "flex", gap: "20px" }}>
            <Box style={styles.departmentsList}>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "37px",
                }}
              >
                <Box style={styles.cardHead} >
                  <span style={styles.card_heading}>
                    {configJSON.departmentLabel}
                  </span>
                  {this.state.departmentsCount !==0 && (
                    <span style={styles.department_count}>
                      ({this.state.departmentsCount})
                    </span>
                  )}
                </Box>
                <Button style={styles.export_button}>{configJSON.exportBTN}</Button>
              </Box>
              <Box>
                {this.state.departmentsList?.length > 0 &&
                  <>
                    {this.state.departmentsList?.map((department: DepartmentData, index: number) => (
                      <DepartmentItem
                        data-test-id='department_field'
                        key={`key-${department.id}-${index}`}
                        onClick={() => this.handleDepartmentClick(department.id)}
                      >
                        <Box
                          style={styles.truncatedTextHead}
                          data-test-id='department_field_name'
                          onMouseEnter={(event: React.MouseEvent<HTMLElement>) => {
                            if (department.department_name.length > 20) {
                              this.handelDepartmentNameModal(event, true, department?.department_name)
                            } else {
                              this.handelDepartmentNameModal(null, false, "")
                            }
                          }}
                        >
                          {department.department_name.length > 20 ? (
                            <>
                              {department.department_name.slice(0, 20)}...
                            </>
                          ) : (
                            department.department_name
                          )}
                        </Box>
                        <UserDataField>
                          <PrimeUserField>
                            <Box>
                              <p>PRIME USER</p>
                              <Box style={styles.truncatedText}>
                                {department.department_head || "--"}
                              </Box>

                            </Box>
                            <ImageSection>
                              {department.department_head && this.getShortUserName(department.department_head)}
                            </ImageSection>
                          </PrimeUserField>
                          <PrimeUserField>
                            <Box>
                              <p>DEPARTMENT HEAD</p>
                              <Box style={styles.truncatedText}>
                                {department.prime_user || "--"}
                              </Box>

                            </Box>
                            <ImageSection>
                              {department.prime_user && this.getShortUserName(department.prime_user)}
                            </ImageSection>
                          </PrimeUserField>

                        </UserDataField>
                      </DepartmentItem>
                    ))}
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 15]}
                      count={this.state.departmentsCount}
                      rowsPerPage={this.state.rowsPerPage}
                      page={this.state.page}
                      onPageChange={this.onPageChange}
                      component="div"
                      onRowsPerPageChange={this.onRowsPerPageChange}
                    />
                  </>
                }
                {this.state.departmentsList?.length <= 0 && <Typography>{configJSON.emptyDataMessage}</Typography>}
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "42px",
                }}
              >
                <Button style={styles.create_button} onClick={() => this.handleModalOpen(true)}
                  data-test-id="openModal">
                  {configJSON.addNewDepartment}
                </Button>
              </Box>
            </Box>
            <RightSidebar data-test-id="rightSidebar" pathName="Departments" handleNavigation={this.handleNavigation} navigationList={this.navigationList} />
          </Box>
        </Box>

        <Dialog
          open={this.state.showModal}
          onClose={() => this.handleModalOpen(false)}
          fullWidth={true}
          maxWidth='lg'
          data-test-id='close-dialog'
        >
          <Box p={'24px'}>
            <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
              <Typography style={styles.table_heading}>Add department</Typography>
              <span style={styles.cancel_icon} onClick={this.handleModalClose} data-test-id="cancelIcon">
                <img src={cancelIcon} alt="Cancel..." />
              </span>
            </Box>
            <form onSubmit={this.submitHandler} data-test-id='form-component'>

            
              <FlexField>
                <Box style={{width:'50%'}}>
                  <div>
                    <p style={styles.department_label}>Department Name<span style={styles.department_start}>*</span></p>
                    <InputField
                      data-test-id = "departmentName"
                      onChange={(event)=>this.handleFieldChange('departmentName',event.target.value)}
                      value={this.state.departmentFormValues.departmentName}
                      error={Boolean(this.getFieldError('departmentName',this.state.departmentFormValues.departmentName,this.state.departmentTouched))}
                      placeholder="Enter Department Name"
                      style={styles.inputField} variant="outlined" />
                      <p style={{color:"#F22A2A",margin:0,fontSize:'12px'}}>{this.getFieldError('departmentName',this.state.departmentFormValues.departmentName,this.state.departmentTouched)}</p>
                  </div>
                  <div style={styles.discriptionField}>
                    <p style={styles.department_label}>Description</p>
                    <DescriptionField 
                      data-test-id = "description"
                      onChange={(event)=>this.handleFieldChange('description',event.target.value)}
                      value={this.state.departmentFormValues.description}
                      style={{color:this.state.departmentFormValues.description?"#33354D":'#A3A6C2'}}
                      placeholder="Enter description"
                      />
                      <p></p>
                  </div>
                </Box>
                <Box style={{width:'50%',marginLeft:'24px'}}>
                    <div>
                      <p style={styles.department_label}>Domains for this Department</p>
                      <InputField 
                        data-test-id = "domain"
                        onChange={(event)=>this.handleFieldChange('domain',event.target.value)}
                        value={this.state.departmentFormValues.domain}
                        placeholder="Enter Domains for this Department"
                        style={styles.inputField} variant="outlined" />
                    </div>
                    <div style={styles.discriptionField}>
                      <p style={styles.department_label}>Department Head</p>
                      <InputField 
                        data-test-id = "headName"
                        onChange={(event)=>this.handleFieldChange('headName',event.target.value)}
                        error={Boolean(this.getFieldError('headName',this.state.departmentFormValues.headName,this.state.departmentTouched))}
                        value={this.state.departmentFormValues.headName}
                        placeholder="Enter users name or email"
                        style={styles.inputField} variant="outlined" />
                      <p style={{color:"#F22A2A",margin:0,fontSize:'12px'}}>{this.getFieldError('headName',this.state.departmentFormValues.headName,this.state.departmentTouched)}</p>
                    </div>
                    <div style={styles.discriptionField}>
                      <p style={styles.department_label}>Prime User</p>
                      <InputField 
                        data-test-id = "primeUserName"
                        error={Boolean(this.getFieldError('primeUserName',this.state.departmentFormValues.primeUserName,this.state.departmentTouched))}
                        onChange={(event)=>this.handleFieldChange('primeUserName',event.target.value)}
                        value={this.state.departmentFormValues.primeUserName}
                        placeholder="Enter users name or email"
                        style={styles.inputField} variant="outlined" />
                      <p style={{color:"#F22A2A",margin:0,fontSize:'12px'}}>{this.getFieldError('primeUserName',this.state.departmentFormValues.primeUserName,this.state.departmentTouched)}</p>
                    </div>
                </Box>
              </FlexField>
              <Box mt={'30px'} style={styles.modal_button_main_box}>
                <Button onClick={this.handleModalClose} data-test-id='modal_Cancel_Btn' type="reset" style={styles.cancel_button}>{configJSON.cancelBTN}</Button>
                <Button type="submit" style={styles.add_button} >Add department</Button>
            </Box>
            </form>
          </Box>
        </Dialog >
        <Dialog
          open={this.state.showEditModal}
          onClose={this.handleEditModalClose}
          fullWidth={true}
          maxWidth='lg'
          data-test-id='editDialog'
        >
          <Box p={'1rem'}>
            <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
              <Typography style={styles.table_heading} data-test-id="editDepartmentTestId">{configJSON.editDepartment}</Typography>
              <span style={styles.cancel_icon} onClick={this.handleEditModalClose} data-test-id="cancelEditIcon">
                <img src={cancelIcon} alt="Cancel..." />
              </span>
            </Box>
           {this.renderForm(this.state.editData.attributes,this.handleEditSubmit,"formikEditClickEvent")}
          </Box>
        </Dialog >
        <CommonModal
          data-test-id="infoModal"
          isOpen={this.state.isInfoModalOpen}
          onClose={() => this.handelInfoModal(false)}
          title={configJSON.departmentsModalTitle}
          anchorEl={this.state.anchorEl}
          id="infoIcon"
          data={[
            configJSON.departmentsInfoMsg1,
            configJSON.departmentsInfoMsg2
          ]}
        />

        <CommonModal
          data-test-id="departmentTextModal"
          isOpen={this.state.isDepartmentNameModalOpen}
          onClose={() => this.handelDepartmentNameModal(null,false, "")}
          title={""}
          anchorEl={this.state.anchorEl}
          id="infoIcon"
          isVisibleCloseIcon={false}
          data={[
            this.state.departmentNameValue
          ]}
        /> 
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const dropDownStyles = {
  control: (provided: ControlProps<AgentsOptionList, false>, state: { isFocused: boolean }) => ({
    ...provided,
    height: "55px",
    width: "100%",
    "&:hover": {
      border: state.isFocused ? "1px solid #3F50B5" : "1px solid black",
      boxShadow: state.isFocused ? "0 0 0 1px #3F50B5" : "",
    },
    border: state.isFocused ? "1px solid #3F50B5" : "",
    boxShadow: state.isFocused ? "0 0 0 1px #3F50B5" : "",
  }),
};

const InputField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    "& input::placeholder": {
      color: "#A3A6C2",
    },
    "& input":{
      color:"#33354D",
      fontFamily:"Manrope",
      lineHeight:"22.4px",
    padding:'10px',
    borderRadius: "8px",
    border:"2px solid #E6DEED !important",
    
  }
},
})

const FlexField = styled(Box)({
  display:'flex',
  width:'100%'
})

const DepartmentItem = styled(Box)({
  display:'flex',
  border:"1px solid #E6DEED",
  padding:"17px 12px",
  borderRadius:'8px',
  marginBottom:"8px",
  maxWidth:'100%',
  justifyContent:'space-between',
  alignItems:'center',
  cursor:'pointer'
})

const PrimeUserField = styled(Box)({
  display:'flex',
  alignItems:'center',
  "& p":{
    fontFamily:"Manrope",
    fontSize:'11px',
    color:'#A3A6C2',
    margin:0,
    textAlign:'right'
  },
  "& div":{
    textAlign:'right',
    color:"#33354D",
    fontWeight:600
  },
  minWidth:'200px',
  justifyContent:'end'
})

const ImageSection = styled(Box)({
  height:'32px',
  width:'32px',
  backgroundColor:"#E6DEED",
  borderRadius:'50%',
  marginLeft:'10px',
  color:"#33354D",
  display:'flex',
  justifyContent:'center',
  alignItems:'center'
})

const UserDataField = styled(Box)({
  display:'flex',
  width:'40%',
  justifyContent:'end',
  gap:'24px'
})

const DescriptionField = styled("textarea")({
  fontFamily: 'Manrope',
  border:"2px solid #E6DEED",
  fontWeight:400,
  fontSize:'16px',
  lineHeight:'22.4px',
  padding:"10px",
  height:'133px',
  borderRadius:'8px',
  resize:'none',
  width:'100%',
  color:'black',

  '::placeholder': {
    color: 'red'
  },

  '::-webkit-input-placeholder': {
    color: 'red'
  },
  '::-moz-placeholder': {
    color: 'red'
  },
  ':-ms-input-placeholder': {
    color: 'red'
  },
  ':-moz-placeholder': {
    color: 'red'
  },

  
  '&:hover': {
    border: '1px solid black', // Change border color to blue when focused
    outline: 'none' // Remove the default focus outline if needed
  },
  '&:focus': {
    border: '2px solid #5467C2', // Change border color to blue when focused
    outline: 'none' // Remove the default focus outline if needed
  },

})

const styles = {
  container: {
    paddingBottom: "25px",
    height: "100%",
    margin: "15px 15px 15px 300px",
    fontFamily: "Manrope",
  } as const,
  heading: {
    fontSize: "30px",
    margin: "10px 0 0",
    lineHeight: "40px",
    fontWeight: "bold",
    color: "#33354D",
  } as const,
  arrow: {
    display: "flex",
    alignItems: "center",
    height: "28px",
    justifyContent: "center",
    borderRadius: "100px",
    border: "1px solid #E6DEED",
    width: "28px",
    cursor: "pointer",
    backgroundColor: "white",
  } as const,
  arrow_icon: {
    width: "20px",
    height: "20px",
    color: "#33354D",
  } as const,


  table_heading: {
    color: "#33354D",
    fontStyle: "normal",
    fontFamily: 'Manrope',
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "normal",
    marginBottom: '30px'
  } as const,
  card_heading: {
    color: "#33354D",
    fontFamily: 'Manrope',
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
  } as const,

  export_button: {
    fontFamily: "Manrope",
    fontWeight: "bolder",
    fontSize: "16px",
    lineHeight: "16px",
    color: "#8760A9",
    letterSpacing: "0em",
    textTransform: "capitalize",
  } as const,

  department_count: {
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "19px",
  } as const,
  departmentsList: {
    borderRadius: "8px",
    flex: "1",
    alignSelf: "flex-start",
    padding: "16px",
    boxShadow: '0px 8px 32px 0px rgba(135, 96, 169, 0.16), 0px 4px 8px 0px rgba(135, 96, 169, 0.13)'
  } as const,

  department: {
    display: "flex",
    alignItems: "center",
    gap: "26px",
    borderRadius: "8px",
    border: "1px solid #E6DEED",
    padding: "17px 12px",
    height: "58px",
  } as const,

  field: {
    width:'40%',
    "& p":{
      color:'red'
    }
  } as const,
  discriptionField: {
    marginTop:'24px'
  } as const,
  inputField: {
    width:'100%',
    borderRadius:'8px !important',
    color:"#A3A6C2"
  } as const,
  department_label: {
    fontFamily: "Manrope",
    fontSize: "14px",
    color: "#3D405C",
    margin:0,
    marginBottom:"4px"
  } as const,
  department_start: {
    color: "#F22A2A",
    marginLeft:'4px'
  } as const,

  create_button: {
    padding: "10px 16px",
    backgroundColor: "#E6DEED",
    color: "#33354D",
    fontFamily: "Manrope",
    fontWeight: "bolder",
    fontSize: "16px",
    letterSpacing: "0em",
    lineHeight: "16px",
    textTransform: "initial",
    borderRadius: "4px",
    cursor:'pointer'
  } as const,

  add_button: {
    padding: "10px 16px",
    backgroundColor: "#8760A9",
    fontSize: "16px",
    color: "#FFF",
    fontWeight: 600,
    fontFamily: "Manrope",
    lineHeight: "16px",
    letterSpacing: "0em",
    borderRadius: "4px",
    textTransform: "initial",
    height:'48px',
    cursor:'pointer'
  } as const,

  cancel_button: {
    padding: "10px 16px",
    fontSize: "16px",
    fontFamily: "Manrope",
    backgroundColor: "transparent",
    fontWeight: 600,
    color: "#8760A9",
    lineHeight: "16px",
    letterSpacing: "0em",
    borderRadius: "4px",
    textTransform: "initial",
    cursor:'pointer'
  } as const,
  accordians: {
    boxShadow: "0px 4px 8px 0px #8760A921",
    flexBasis: "30%",
    borderRadius: "8px",
    padding: "4px",
  } as const,
  navigation: {
    fontFamily: "Manrope",
    fontSize: "14px",
    lineHeight: "19px",
  } as const,

  search_icon: {
    height: "24px",
    width: "24px",
    position: "absolute",
    color: "#33354D",
    top: "50%",
    left: "10px",
    transform: "translateY(-50%)",
  } as const,
  input: {
    fontFamily: "Manrope",
    width: "100%",
    height: "40px",
    border: "1px solid #e6deed",
    padding: "10px 10px 10px 40px",
    fontSize: "16px",
    borderRadius: "10px",
  } as const,
  navigation_item: {
    borderRadius: "4px",
    padding: "9px, 20px, 9px, 16px",
  } as const,

  navigation_item_nested: {
    borderRadius: "4px",
    padding: "9px 20px 9px 30px",
  } as const,

  navigation_item_active: {
    color: "white",
    backgroundColor: "#8760A9",
  } as const,

  navigation_link: {
    fontFamily: "Manrope",
    fontWeight: "bold",
    fontSize: "14px",
    color: "#33354D",
    lineHeight: "22px",
    letterSpacing: "0em",
  } as const,
  navigation_link_active: {
    color: "white",
  } as const,
  form_main_box: {
    padding: '1rem',
  } as const,
  cardHead: {
    alignItems: "center",
    display: "flex",
    gap: "5px",
  },
  department_main_box: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  } as const,
  modal_button_main_box: {
    // gap: '1rem',
    float: 'right',
    display: 'flex',
  } as const,
  truncatedText: {
    maxWidth: '150px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color:"#33354D",
    with:'150px'
    // width:'50%'
  } as const,
  truncatedTextHead: {
    maxWidth: '200px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width:'50%',
    fontFamily:"Manrope",
    fontWeight:700,
    fontSize:"16px",
    lineHeight:"24px",
    cursor:'pointer'
  } as const,

  cancel_icon: { 
    height: "30px",
    width: "30px"
   },

   input_label: {
    marginBottom: "5px",
   },

   department_span: {
    color: "red"
   },

   infoIconsStyle: {
    cursor: "pointer",
    color: "#8760A9",
    marginLeft: "10px"
   }

};

// Customizable Area End