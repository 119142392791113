import React from "react";

// Customizable Area Start
import {
  Button,
  Input,
  Typography,
  Dialog,
  Box,
  TextField,
  styled,
  FormControl,
  Select,
  MenuItem,
  Card
} from "@material-ui/core";
import { Toaster } from 'react-hot-toast';
import {
  MoreVert,
  Search,
  RemoveCircleOutline,
  AddCircleOutline,
} from "@material-ui/icons";
import {  cancelIcon,DropDpwnIcon } from './assets';

import  {  Props, configJSON } from "./DepartmentsController";
import NavigationSideBar from "../../../components/src/NavigationSideBar";
import DepartmentInfoController, { SelectedAgent,AgentType } from "./DepartmentInfoController.web";
// Customizable Area End

export default class DepartmentInfo extends DepartmentInfoController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderEditPaper = () => {
    return (
        <>
            <DetailsContainer>
                <DetailsText>Details</DetailsText>
                <DetailsSubContainer>
                    <DetailsColumn1>
                        <DetailsField>
                            <DetailsFont1>Department Head</DetailsFont1>
                            <DetailsFont2>{this.state.departmentDetails.department_head ? this.state.departmentDetails.department_head : "-"}</DetailsFont2>

                        </DetailsField>
                        <DetailsField>
                            <DetailsFont1>Prime User</DetailsFont1>
                            <DetailsFont2>{this.state.departmentDetails.prime_user ? this.state.departmentDetails.prime_user : "-"}</DetailsFont2>

                        </DetailsField>
                        <DetailsField>
                            <DetailsFont1>Domains</DetailsFont1>
                            <DetailsFont2>{this.state.departmentDetails.domains_for_department ? this.state.departmentDetails.domains_for_department : "-"}</DetailsFont2>

                        </DetailsField>
                        <DetailsField>
                            <DetailsFont1>Description</DetailsFont1>
                            <DetailsFont2>{this.state.departmentDetails.description ? this.state.departmentDetails.description : "-"}</DetailsFont2>

                        </DetailsField>
                    </DetailsColumn1>
                    {/* <DetailsColumn2>
                    </DetailsColumn2> */}
                </DetailsSubContainer>

                <BtnContainer>
                    <PrimaryButon
                        // variant="contained"
                        onClick={()=>this.handleModalChange(true)}
                        data-test-id="open_editmodal"
                        // style={webStyle.button}
                        >
                        Edit details
                    </PrimaryButon>
                </BtnContainer>
            </DetailsContainer>
        </>
    )
}

  

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <NavigationSideBar active={'Settings'} navigation={this.props.navigation} />
        <Toaster />
        <DepartmentContainer>
          <BreadcrumbContainer
          >
            <span style={styles.navigation}>
              Settings {">"} User management {">"} Department Fields
            </span>
            <Box style={{ borderRadius: '5px', background: '#e6deed', }}>
              <span style={{ width: '36px', height: '36px', }}>
                <MoreVert style={{ width: '24px', height: '24px', color: 'black', padding: '5px' }} />
              </span>
            </Box>
          </BreadcrumbContainer>

          <DepartmentLabelContainer>
            <Box style={styles.department_main_box}>
              <h1 style={styles.heading} dat-test-id="departmentTestId">{configJSON.departmentLabel}</h1>
            </Box>
          </DepartmentLabelContainer>

          <Box style={{ marginBottom: "32px", position: "relative", }}>
            <Search style={styles.search_icon} />
            <Input
              disableUnderline={true}
              data-test-id="searchInput"
              style={styles.input}
              placeholder={"Search..."}
              onChange={(event) => this.handleSearchInputChange(event)}
            />
          </Box>
          <Box style={{ display: "flex", gap: "20px" }}>
            <Box style={styles.departmentsList}>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "37px",
                }}
              >
                <Box style={styles.cardHead} >
                  <span style={styles.card_heading}>
                    Members
                  </span>
                </Box>
              </Box>
              <Box>
                {this.state.departmentDetails?.members?.length === 0 ? <Typography style={styles.generalFontStyle1}>{configJSON.nomembersadded}</Typography> : this.state.departmentDetails?.members?.map((memberData) => {
                  return (
                    <MainCard variant="outlined" key={memberData.id}>
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        <ImageSection>
                          {memberData?.full_name && this.getShortUserName(memberData?.full_name)}
                        </ImageSection>
                        <Box>
                          <CardTitle>{memberData?.full_name}</CardTitle>
                        </Box>
                      </Box>
                      <Box>
                        <CardParagraph>{memberData?.email}</CardParagraph>
                      </Box>
                    </MainCard>
                  )
                })}
              </Box>
                            <Box
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "42px",
                }}
              >
                <PrimaryButon
                    // variant="contained"
                    onClick={() => this.handleMemberModalChange(true)}
                  data-test-id="openModalMember">
                  Add members
                </PrimaryButon>
              </Box>
            </Box>
            {this.renderEditPaper()}
          </Box>
        </DepartmentContainer>

        <Dialog
          open={this.state.showModal}
          onClose={() => this.handleModalChange(false)}
          fullWidth={true}
          maxWidth='lg'
          data-test-id='close-dialog'
        >
          <Box p={'24px'}>
            <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
              <Typography style={styles.table_heading}>Add department</Typography>
              <span style={styles.cancel_icon} 
              onClick={()=>this.handleModalChange(false)} 
              data-test-id="cancelIcon">
                <img src={cancelIcon} alt="Cancel..." />
              </span>
            </Box>
            <form 
            onSubmit={this.handleSubmitEditForm}
             data-test-id='form-component'>

            
              <FlexField>
                <Box style={{width:'50%'}}>
                  <div>
                    <p style={styles.department_label}>Department Name<span style={styles.department_start}>*</span></p>
                    <InputField
                      data-test-id = "departmentName"
                      onChange={(event)=>this.handleFieldValueChange('department_name',event.target.value)}
                      value={this.state.editDetailsForm.department_name}
                      error={Boolean(this.getEditFormFieldError('department_name',this.state.editDetailsForm.department_name,this.state.departmentTouched))}
                      placeholder="Enter Department Name"
                      style={styles.inputField} variant="outlined" />
                      <p style={{color:"#F22A2A",margin:0,fontSize:'12px'}}>{this.getEditFormFieldError('department_name',this.state.editDetailsForm.department_name,this.state.departmentTouched)}</p>
                  </div>
                  <div style={styles.discriptionField}>
                    <p style={styles.department_label}>Description</p>
                    <DescriptionFieldEdit 
                      data-test-id = "description"
                      onChange={(event)=>this.handleFieldValueChange('description',event.target.value)}
                    value={this.state.editDetailsForm.description}
                      placeholder="Enter description"
                      />
                      <p></p>
                  </div>
                </Box>
                <Box style={{width:'50%',marginLeft:'24px'}}>
                    <div>
                      <p style={styles.department_label}>Domains for this Department</p>
                      <InputField 
                        data-test-id = "domain"
                        onChange={(event)=>this.handleFieldValueChange('domains_for_department',event.target.value)}
                        value={this.state.editDetailsForm.domains_for_department}
                        placeholder="Enter Domains for this Department"
                        style={styles.inputField} variant="outlined" />
                    </div>
                    <div style={styles.discriptionField}>
                      <p style={styles.department_label}>Department Head</p>
                      <InputField 
                        data-test-id = "headName"
                        onChange={(event)=>this.handleFieldValueChange("department_head",event.target.value)}
                        error={Boolean(this.getEditFormFieldError('department_head',this.state.editDetailsForm.department_head,this.state.departmentTouched))}
                        value={this.state.editDetailsForm.department_head}
                        placeholder="Enter users name or email"
                        style={styles.inputField} variant="outlined" />
                      <p style={{color:"#F22A2A",margin:0,fontSize:'12px'}}>{this.getEditFormFieldError('department_head',this.state.editDetailsForm.department_head,this.state.departmentTouched)}</p>
                    </div>
                    <div style={styles.discriptionField}>
                      <p style={styles.department_label}>Prime User</p>
                      <InputField 
                        error={Boolean(this.getEditFormFieldError('prime_user',this.state.editDetailsForm.prime_user,this.state.departmentTouched))}
                        data-test-id = "primeUserName"
                        onChange={(event)=>this.handleFieldValueChange('prime_user',event.target.value)}
                        value={this.state.editDetailsForm.prime_user}
                        placeholder="Enter users name or email"
                        style={styles.inputField} variant="outlined" />
                      <p style={{color:"#F22A2A",margin:0,fontSize:'12px'}}>{this.getEditFormFieldError('prime_user',this.state.editDetailsForm.prime_user,this.state.departmentTouched)}</p>
                    </div>
                </Box>
              </FlexField>
              <Box mt={'30px'} style={styles.modal_button_main_box}>
                <Button 
                onClick={()=>this.handleModalChange(false)} 
                data-test-id='close_modal_btn' type="reset" style={styles.cancel_button_main}>{configJSON.cancelBTN}</Button>
                <Button type="submit" style={styles.add_button_main} >Save changes</Button>
            </Box>
            </form>
          </Box>
        </Dialog >
        <Dialog data-test-id="add_member_modal" onClose={()=>this.handleMemberModalChange(false)} open={this.state.showMemberModal}>
            <AddMemberContainer p={'24'}>
                <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
                <Typography style={styles.table_heading}>Add members</Typography>
                <span style={styles.cancel_icon} 
                onClick={()=>this.handleMemberModalChange(false)} 
                data-test-id="cancelIcon">
                    <img src={cancelIcon} alt="Cancel..." />
                </span>
                </Box>
                <MembersContainer>
                    <MemberFieldText>Member</MemberFieldText>
                    {this.state.selectedAgents.length>0 && this.state.selectedAgents.map((selectedAgent:SelectedAgent,selectedIndex:number)=>{
                        return <MemberSelectField data-test-id="select_member" key={selectedIndex}>
                                    <SelectField data-test-id="select_field" fullWidth variant="outlined" key={selectedIndex}>
                                        <Select
                                            value={selectedAgent.value}
                                            onChange={(event)=>{
                                              const getSelectedAgentData = this.state.allAgents.find((agentData)=>agentData?.attributes.full_name === event.target.value);
                                              this.handleSelectedMemberChange(selectedIndex, { id: getSelectedAgentData?.id ?? "", type: getSelectedAgentData?.type ?? "", value: `${event.target.value}` })
                                            }}
                                            displayEmpty
                                            data-test-id="select_member_field"
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            IconComponent={()=><img src={DropDpwnIcon} />}
                                            style={selectedAgent.value?webStyles.filledValue:webStyles.blankValue}
                                            key={selectedIndex}
                                        >
                                            <FirstMenuItem value="" disabled>
                                                Select Member
                                            </FirstMenuItem>
                                            {this.state.allAgents.map((agent:AgentType)=>{
                                                return <MenuItem key={selectedIndex} disabled={this.isOptionDisabled(agent.attributes.full_name)} value={agent.attributes.full_name}>{agent.attributes.full_name}</MenuItem>
                                            })}
                                        </Select>
                                        </SelectField>
                                        <RemoveCircleOutline
                                            style={webStyles.removeIconStyle}
                                            onClick={() => this.handleDeleteSelectedMember(selectedIndex)}
                                            data-test-id='remove_delete'
                                        />
                                </MemberSelectField>
                    })}
                    <Box style={webStyles.flexEnd} m={'0 10px'}>
                <Button onClick={() => this.addMember()} variant="text" data-test-id='add_member' style={{
                  ...webStyles.addIconStyle,
                  textTransform: 'inherit',
                  cursor: this.isDisabled() ? 'not-allowed' : 'pointer',
                  backgroundColor: this.isDisabled() ? '#eaeaea' : 'transparent',
                }} disabled={this.isDisabled()} endIcon={<AddCircleOutline style={{ height: '24px', width: '24px' }} />}>Add member</Button>
                    </Box>
                </MembersContainer>
                <Box mt={'240px'} style={styles.modal_button_main_box}>
                <Button 
                    onClick={()=>this.handleMemberModalChange(false)} 
                    data-test-id='modal_Cancel_Btn' type="reset" style={styles.cancel_button_main}>{configJSON.cancelBTN}</Button>
                <Button data-test-id='modal_save_changes_Btn' type="submit" style={styles.add_button_main} onClick={this.onFormSubmit}>Save changes</Button>
            </Box>
            </AddMemberContainer>
        </Dialog>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const InputField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    "& input::placeholder": {
      color: "#A3A6C2",
    },
    "& input":{
      color:"#33354D",
      fontFamily:"Manrope",
      lineHeight:"22.4px",
    padding:'10px',
    borderRadius: "8px",
    border:"2px solid #E6DEED !important",
    
  }
},
})

const DepartmentContainer = styled(Box)({
    paddingBottom: "25px",
    height: "100%",
    margin: "15px 15px 15px 300px",
    fontFamily: "Manrope",
})

const BreadcrumbContainer = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
})

const DepartmentLabelContainer = styled(Box)({
    marginTop: "60px",
    marginBottom: "50px",
})

const MembersContainer = styled(Box)({
    marginTop:"36px"
})

const MemberFieldText = styled('p')({
    color:"#3D405C",
    fontFamily:"Manrope",
    fontWeight:500,
    fontSize:"12px",
    lineHeight:"16.39px",
    margin:'0',
    marginBottom:'4px'
})

const MemberSelectField = styled(Box)({
    display:'flex',
    alignItems:'center',
    marginBottom:'8px'
    
  
})

const SelectField = styled(FormControl)({
    // border:'2px solid red',
    border:'1px solid #E6DEED',
    borderRadius:'8px',
    width:'450px',
    "& div":{
        borderRadius:'8px',
        paddingRight:'17px'
    },
    "& .MuiOutlinedInput-input":{
        padding:'12px'
    }

})

const FirstMenuItem = styled(MenuItem)({
    fontFamily:'Manrope',
    fontWeight:400,
    fontSize:'16px',
    lineHeight:'22.4px'
})

const AddMemberContainer = styled(Box)({
    width:'500px',
    maxHeight:'550px',
    overflowY:'auto'
})


const DetailsText = styled(Typography)({
    fontFamily: 'Manrope',
    fontSize: '20px',
    fontWeight: 700,
    color: '#33354D'
})

const DetailsSubContainer = styled(Box)({
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '25px'
})

const DetailsColumn1 = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
})

const DetailsField = styled(Box)({
    display:'flex',
    justifyContent:"space-between"
})

const BtnContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginRight: "8px",
    marginTop: '30px'
})

const DetailsFont1 = styled(Typography)({
    fontFamily: 'Manrope',
    fontSize: '14px',
    fontWeight: 600,
    color: '#A3A6C2',
})

const DetailsFont2 = styled(Typography)({
    fontFamily: 'Manrope',
    fontSize: '14px',
    fontWeight: 700,
    color: '#33354D',
    maxWidth:'150px',
    overflow:'hidden',
    textOverflow:"ellipsis"
})

const DetailsContainer = styled(Box)({
    boxShadow: "rgba(135, 96, 169, 0.16) 0px 8px 32px 0px, rgba(135, 96, 169, 0.13) 0px 4px 8px 0px",
    flexBasis: "30%",
    borderRadius: "8px",
    padding: "16px",
    maxWidth:'300px'
})

const FlexField = styled(Box)({
  display:'flex',
  width:'100%'
})

const PrimaryButon = styled(Button)({

    padding: "10px 16px",
    backgroundColor: "#E6DEED",
    color: "#33354D",
    fontFamily: "Manrope",
    fontWeight: "bolder",
    fontSize: "16px",
    letterSpacing: "0em",
    lineHeight: "16px",
    textTransform: "initial",
    borderRadius: "4px",
    cursor:'pointer',
    "&:hover":{
        backgroundColor: "#E6DEED",

    }
})

const DescriptionFieldEdit = styled("textarea")({
  borderRadius:'8px',
  resize:'none',
  width:'100%',
  color:'black',

  '::placeholder': {
    color: 'red'
  },

  '::-webkit-input-placeholder': {
    color: 'red'
  },
  '::-moz-placeholder': {
    color: 'red'
  },
  ':-ms-input-placeholder': {
    color: 'red'
  },
  ':-moz-placeholder': {
    color: 'red'
  },

  fontWeight:400,
  fontFamily: 'Manrope',
  border:"2px solid #E6DEED",
  lineHeight:'22.4px',
  fontSize:'16px',
  height:'133px',
  padding:"10px",

  
  '&:hover': {
    border: '1px solid black',
    outline: 'none' 
  },
  '&:focus': {
    border: '2px solid #5467C2',
    outline: 'none' 
  },

})

const MainCard = styled(Card)({
  marginBottom: "8px",
  marginTop: '20px',
  padding: "12px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginRight: "8px"
});

const CardTitle = styled(Typography)({
  width: "100%",
  color: "#33354D",
  fontFamily: "Manrope",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "24px"
});

const CardParagraph = styled(Typography)({
  color: "#33354D",
  fontFamily: "Manrope",
  fontSize: "14px",
  fontstyle: "normal",
  fontweight: 600,
  lineheight: "normal",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: '400px'
});

const ImageSection = styled(Box)({
  height:'32px',
  width:'32px',
  backgroundColor:"#E6DEED",
  borderRadius:'50%',
  marginRight:'10px',
  color:"#33354D",
  display:'flex',
  justifyContent:'center',
  alignItems:'center'
})

const webStyles = {
    blankValue:{
        fontFamily:"Manrope",
        fontWeight:400,
        fontSize:'16px',
        lineHeight:"22.4px",
        color:"#A3A6C2"
    } as const,
    filledValue:{
        fontFamily:"Manrope",
        fontWeight:400,
        fontSize:'16px',
        lineHeight:"22.4px",
        color:"#33354D"
    } as const,
    flexEnd: {
        display: 'flex',
        justifyContent: 'flex-end',
    } as const,
    addIconStyle: {
        cursor: 'pointer',
        color: '#8760A9',
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '22px',
        marginTop: '7px'
    } as const,
    removeIconStyle: {
        marginLeft: '10px',
        color: '#8760A9',
        cursor: 'pointer'
    } as const,

    toaster_wrapper: {
        display: "inline-flex",
        padding: "8px 8px 8px 8px",
        justifyContent: "space-between",
        alignItems: "center",
        width: "900px",
        height: "44px"
    },

    toaster_container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%"
    } as const,

    toaster_box: {
        display: "flex",
        alignItems: "center"
    } as const,

    toaster_msg: {
        marginLeft: "10px",
        fontSize: "12px",
        fontFamily: 'Manrope',
        lineHeight: 'normal',
        color: '#33354D'
    } as const,
    toastIcon: {
        height: '26px',
        width: '26px',
    }as const,
    toastCancelIcon: {
        cursor: "pointer",
    } as const
};

const styles = {
  heading: {
    fontSize: "30px",
    margin: "10px 0 0",
    lineHeight: "40px",
    fontWeight: "bold",
    color: "#33354D",
  } as const,


  table_heading: {
    color: "#33354D",
    fontStyle: "normal",
    fontFamily: 'Manrope',
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "normal",
    marginBottom: '30px'
  } as const,
  card_heading: {
    color: "#33354D",
    fontFamily: 'Manrope',
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
  } as const,

  departmentsList: {
    borderRadius: "8px",
    flex: "1",
    alignSelf: "flex-start",
    padding: "16px",
    boxShadow: '0px 8px 32px 0px rgba(135, 96, 169, 0.16), 0px 4px 8px 0px rgba(135, 96, 169, 0.13)'
  } as const,

  department: {
    display: "flex",
    alignItems: "center",
    gap: "26px",
    borderRadius: "8px",
    border: "1px solid #E6DEED",
    padding: "17px 12px",
    height: "58px",
  } as const,

  discriptionField: {
    marginTop:'24px'
  } as const,
  inputField: {
    width:'100%',
    borderRadius:'8px !important',
    color:"#A3A6C2"
  } as const,
  department_label: {
    fontFamily: "Manrope",
    fontSize: "14px",
    color: "#3D405C",
    margin:0,
    marginBottom:"4px"
  } as const,
  department_start: {
    color: "#F22A2A",
    marginLeft:'4px'
  } as const,

  

  add_button_main: {
      backgroundColor: "#8760A9",
    padding: "10px 16px",
    color: "#FFF",
    fontSize: "16px",
    fontFamily: "Manrope",
    fontWeight: 600,
    letterSpacing: "0em",
    lineHeight: "16px",
    textTransform: "initial",
    borderRadius: "4px",
    cursor:'pointer',
    height:'48px',
  } as const,

  cancel_button_main: {
      fontFamily: "Manrope",
      padding: "10px 16px",
    fontSize: "16px",
    fontWeight: 600,
    backgroundColor: "transparent",
    lineHeight: "16px",
    color: "#8760A9",
    borderRadius: "4px",
    letterSpacing: "0em",
    cursor:'pointer',
    textTransform: "initial",
  } as const,
  navigation: {
    fontFamily: "Manrope",
    fontSize: "14px",
    lineHeight: "19px",
  } as const,

  search_icon: {
    height: "24px",
    width: "24px",
    position: "absolute",
    color: "#33354D",
    top: "50%",
    left: "10px",
    transform: "translateY(-50%)",
  } as const,
  input: {
    fontFamily: "Manrope",
    width: "100%",
    height: "40px",
    border: "1px solid #e6deed",
    padding: "10px 10px 10px 40px",
    fontSize: "16px",
    borderRadius: "10px",
  } as const,
  cardHead: {
    alignItems: "center",
    display: "flex",
    gap: "5px",
  },
  department_main_box: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  } as const,
  modal_button_main_box: {
    // gap: '1rem',
    float: 'right',
    display: 'flex',
  } as const,

  cancel_icon: { 
    height: "30px",
    width: "30px"
   },

   generalFontStyle1: {
    fontFamily: 'Manrope',
    color: '#33354D',
    fontSize: '14px',
    fontWeight: 700,
    textAlign: 'center' as const,
    marginTop: '10px'
},

   };

// Customizable Area End