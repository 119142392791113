import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  TextField
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
  MoreVert,
  Search,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  ArrowDropDown,
  Clear,
} from "@material-ui/icons";
import { Path25 } from "./assets"
import { Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Switch,} from "@material-ui/core"
import { Path2 } from "./assets"
import { Path24 } from "./assets"
import { Path26 } from "./assets"
import { Path1 } from "./assets"
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { Toaster } from "react-hot-toast";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },

});
const errorMessageStyles = {
  color: 'red',
  fontFamily: "'Poppins', sans-serif",
  fontSize: '12px',
  fontWeight: 500,
  marginLeft: '0rem',
  marginTop: '2px',
};
const inputStyles = {
  fontFamily: "'Poppins', sans-serif",
  userSelect: "none",
  border: "1px solid #ccc",
  fontWeight: 400,
  fontSize:"16px",
  } as const
// Customizable Area End

import DemoaccountController, {
  Props,
  configJSON,
} from "./DemoaccountController";

export default class Demoaccount extends DemoaccountController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
      <Toaster />
      <Container style={{ maxWidth: 1440, overflow: "hidden", margin: "0 auto",display:"flex",alignItems:"center",justifyContent:"center"}}>
        <Box sx={webStyle.topFinallogo}>
          {/* <img src={FinalLogo1} alt="My Image" /> */}
        </Box>
        <Dialog open={this.state.isPopupOpen} onClose={this.closePopup}>
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Clear style={{ cursor: "pointer" }} onClick={this.closePopup} />
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography>
            This is your message inside the popup. Please review the details.
          </Typography>
          <Box display="flex" alignItems="center" mt={2}>
            <Typography>Purchase Subscription:</Typography>
            <Switch
              checked={this.state.isSwitchOn}
              onChange={this.handleSwitchChange}
              color="primary"
            />
          </Box>
          <Typography>
            Expiry Date: {new Date().toLocaleDateString()}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.closePopup} >
            OK
          </Button>
        </DialogActions>
         </Dialog>
        {/* <Box sx={webStyle.footerMainWrapperLogin}>
          <Box style={{ display: "flex" }}>
            <Box sx={webStyle.path2StyleLogin}>
              <img src={Path2} alt="Path2" />
            </Box>
            <Box sx={webStyle.path1StyleLogin}>
              <img src={Path1} alt="Path1" />
            </Box>
          </Box>
          <Box style={{ display: "flex" }}>
            <Box sx={webStyle.path24StyleLogin}>
              <img src={Path24} alt="Path24" />
            </Box>
            <Box sx={webStyle.path25StyleLogin}>
              <img src={Path25} alt="Path23" />
            </Box>
            <Box sx={webStyle.path24StyleLogin}>
              <img src={Path26} alt="Path26" />
            </Box>
          </Box>
        </Box> */}
      </Container>
    </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  topFinallogo: {
    position: 'absolute',
    left: '120px',
    right: '1193px',
    bottom: '920px',
    top: '94px',

  },
  button: {
    backgroundColor: "#8760A9",
    "&:hover": {
      backgroundColor: "#3700b3",
    },
   
  },
  buttonwithout: {
    backgroundColor: "rgb(172, 168, 168)",
      color: "#9e9e9e",
    
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  pprBoxWrapper: {
    position: "relative",
    marginTop: "252px",
    marginLeft: "470px",
    marginRight: "470px",
    marginBottom: "251px",
  },
  pprLogin: {
    width: 700,

    borderRadius: "var(--1, 8px)",
    background: "var(--basic-white, #FFF)",
    boxShadow:
      "0px 8px 32px 0px rgba(135, 96, 169, 0.16), 0px 4px 8px 0px rgba(135, 96, 169, 0.13)",

  },
  footerMainWrapperLogin: {
    display: "flex", justifyContent: "space-between"
  },
  path2StyleLogin: {
    position: "absolute", zIndex: 1, bottom: 0
  },
  path1StyleLogin: {
    position: "absolute", zIndex: 1, bottom: 0, left: 0
  },
  path24StyleLogin: {
    position: "absolute", zIndex: 1, bottom: 0, right: 0
  },
  path25StyleLogin: {
    position: "absolute", zIndex: 1, bottom: 0, right: "45px"
  }

};


const buttonContainerStyle = {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: 12,
  marginTop: "20px",
  borderRadius: "0px 0px var(--1, 8px) var(--1, 8px)",
  background: "var(--basic-white, #FFF)",
};


// Customizable Area End
