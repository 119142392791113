Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "demoaccount";
exports.labelBodyText = "demoaccount Body";
exports.emailValidText = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; 
exports.hasUpperLower = function (str) {
  return /[a-z]/.test(str) && /[A-Z]/.test(str);
};
exports.nameValid = function (str) {
  const lengthValid = str.length >= 2 && str.length <= 50;
  const charactersValid = /^[a-zA-Z]+$/.test(str);
  return str.trim() !== '' && lengthValid && charactersValid;
};
exports.companynamevalid = function (str) {
  const lengthValid = str.length >= 2 && str.length <= 100;
  const charactersValid = /^[a-zA-Z0-9\s\-\,]+$/.test(str);
  return str.trim() !== '' && lengthValid && charactersValid;
};

exports.hasSpecialChar = function (str) {
  return /[@$!%*?&]/.test(str);
};
exports.hasNumber = function (str) {
  return /\d/.test(str);
};
exports.urlSignUp = "bx_block_demoaccount/demo_accounts/demo_login"; 
exports.btnExampleTitle = "CLICK ME";
exports.apiMethodTypeAddDetail="POST"
// Customizable Area End